// React import
import React, { useState, useEffect, memo, useMemo} from 'react'

// External Library imports
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';

// Material-UI imports
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// Utils import
import { areValuesDeeplyEqual } from 'utils/helpers';

// Stylesheet import
import './pdfViewer.css'

import { useSelector } from 'react-redux';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

function stringToRegex(input) {
  const alphaChars = input.match(/[a-zA-Z0-9]/g);
  if (!alphaChars) return new RegExp(input);

  const regexStr = alphaChars.map((chr)=>chr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('\\s*');
  return new RegExp(regexStr);
}

function highlightPattern(text, pattern) {
  return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}

function PdfViewer ({handleDatasheetPageChange,onDocumentLoadSuccess,datasheet}) {
  const experimentalSettings = useSelector(
    (state) => state.ChatBaseContainer.experimentalSettings
  )
  const isHighlightEnabled = experimentalSettings?.isHighlightRetreivedNodes ?? false
  const totalDatasheetPages = datasheet.totalPages

  let hightlightwords = ['']

  if (datasheet.highlightText !== '' && datasheet.highlightText !== undefined) {
    const hightlightedSentence = datasheet.highlightText.split(/\s+/)
    hightlightwords = hightlightedSentence.map(word => word.replace(/[.,:;()]/g, '')).filter(Boolean)
    hightlightwords = hightlightedSentence.filter(word => word !== '')
    const stopwords = ['to', 'the', 'is', 'in', 'or', 'before', 'and', 'after', 'of', 'on', 'at', 'with', 'by', 'for', 'from', 'over', 'under', 'above', 'below', 'between', 'among', 'through', 'into', 'onto', 'off', 'out', 'up', 'are', 'as', 'be', 'but', 'do', 'does', 'did', 'done', 'doing', 'done', 'has', 'have', 'had', 'having', 'will', 'would', 'shall', 'should', 'may', 'might', 'must', 'can', 'could', 'am', 'is', 'are', 'was', 'were', 'been', 'being', 'a', 'an', 'the', 'this', 'that', 'these', 'those', 'here', 'there', 'where', 'when', 'why', 'how', 'which', 'what', 'who', 'whom', 'whose', 'whichever', 'whatever', 'whoever', 'whomever', 'my', 'mine', 'your', 'yours', 'his', 'her', 'hers', 'its', 'our', 'ours', 'their', 'theirs', 'was', 'were', 'am', 'is', 'are', 'be', 'been', 'being', 'have', 'has', 'had', 'do', 'does', 'did', 'doing', 'will', 'would', 'shall', 'should', 'may', 'might', 'must']
    hightlightwords = hightlightwords.filter(word => !stopwords.includes(word.toLowerCase()))
  }

    function highlightPattern(text, highlightwords) {
  
      let words = text.split(' ')
      words = words.filter(word => word !== '')
      let shouldHighLighBool = false
      for (const word of words) {
        for (let i = 0; i < highlightwords.length; i++) {
          if (highlightwords.length > 0 && word.toLowerCase() == highlightwords[i].toLowerCase()) {
            shouldHighLighBool = true
            highlightwords.splice(i, 1)
            break
  
  
          }
        }

  
      }
      if (shouldHighLighBool) {
        return `<mark style="background-color: yellow">${text}</mark>`
      }
      return text
  
    }
  
    const textRenderer = (textItem) => {
      return highlightPattern(textItem.str, hightlightwords)
    }
  

    const viewerWidth = (window.innerWidth-200)/2
    const pdfFileName = useMemo(() => datasheet.blob_file_url, [datasheet.blob_file_url])

    return (
    <div style={{minWidth:viewerWidth+2 ,borderLeft:"2px solid lightgrey", minHeight:'calc(100vh - 160px)'}}>
    {totalDatasheetPages>0 &&
    <Stack alignItems="center" sx={{zIndex:1000,background:"white" ,width:viewerWidth}}>
        <Pagination 
        sx={{margin:"10px auto"}} 
        count={totalDatasheetPages} 
        page={datasheet.currentPageNumber}
        onChange={handleDatasheetPageChange}
        />
    </Stack>
  }
    <div style={{overflowY:"auto",maxHeight:"calc(100vh - 212px)", marginRight:"40px"}}>
    <Document
    
      file={pdfFileName}
      onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page 
        pageNumber={datasheet.currentPageNumber} 
        scale={viewerWidth*0.0015}
         renderAnnotationLayer={false}
         customTextRenderer={ isHighlightEnabled ? textRenderer : ''}
        />
      </Document>
      </div>
    </div>
  )
}

const memoizedPdfViewer = memo(PdfViewer,arePropsEqual)

function arePropsEqual(prevProps, nextProps) {
  return areValuesDeeplyEqual(prevProps.datasheet, nextProps.datasheet)
}

export default memoizedPdfViewer