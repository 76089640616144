/* eslint-disable import/no-anonymous-default-export */
import { IS_MOBILE } from 'utils/Constants'
const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16
    // fontWeight: 'normal',
  },

  '&multiLine': {
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    input: {
      padding: 1,
      border: '2px inset'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
      maxHeight: '300px',
      overflowY: 'scroll'
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#d3e9ff'
      }
    }
  }
}
if (IS_MOBILE) {
  style.suggestions.left = 0
}
export default style
