import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { BOT_NAME } from 'utils/Constants'

export default function BotProgress ({ 
  text = ' is working on the answer',
  overrideWithChild = false,
  flexColumn = false,
  ...props 
}) {
  return (
    <div className={`${flexColumn && 'flex-column'} bot-title`}>
      {!overrideWithChild && <div id="bot-name-progress-bar">{BOT_NAME + text}</div>}
      <CircularProgress />
      {overrideWithChild && <>{props.children}</>}
    </div>
  )
}

BotProgress.propTypes = {
  text: PropTypes.string,
  flexColumn: PropTypes.string,
  overrideWithChild: PropTypes.bool
}
