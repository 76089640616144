import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  UPLOAD_FILE,
  DELETE_FILE,
  UPDATE_SUMMARY,
  UPDATE_SELECTED_FILE,
  UPDATE_SLIDE,
  NAVIGATE_SLIDE,
  UPDATE_NETWORK_STATUS,
  UPDATE_BOT_PROGRESS
} from './AnalyzerContainerSlice'
import Button from '@mui/material/Button'
import UploadFile from '../../component/Analyzer/UploadFile/UploadFile'
import Presentation from '../../component/Analyzer/Presentation/Presentation'
import PresentationSidebar from '../../component/Analyzer/Presentation/PresentationSidebar'
import RichTextField from '../../component/Chat/RichTextField/RichTextField'
import BotProgress from '../../component/Chat/bot_progress/BotProgress'
import {
  getVisualization
} from '../../services/HttpAPIRequests'
import {
  getMentionsObjectsFromArray,
  generateUniqueFileName
} from '../../utils/helpers'
import { ERROR_MESSAGE } from 'utils/Constants'
import './AnalyzerContainer.css'
import MenuItem from '@mui/material/MenuItem'
import SendIcon from '@mui/icons-material/Send'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'

import SessionExpiredDialog from 'component/PopUp/SessionExpiredDialog'

const PLACEHOLDERS = {
  Visualization:
    'Type in how you want to see the data...  use @ for suggestions',
  Edit: 'Enter Instruction to Edit plot... use @ for suggestions'
}
const SUMMARY_TABLE_ROWS = [
  { name: 'Columns', getCellValue: (field) => field.column },
  { name: 'Data Type', getCellValue: (field) => field.properties.dtype },
  {
    name: 'Unique value count',
    getCellValue: (field) => field.properties.num_unique_values
  }
]
export default function AnalyzerContainer () {
  const selectedFile = useSelector(
    (state) => state.AnalyzerContainer.selectedFile
  )
  const fileData = useSelector((state) => state.AnalyzerContainer.fileData)
  const selectedData = fileData[selectedFile]
  const dispatch = useDispatch()
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: ''
  })
  const botProgress = useSelector(
    (state) => state.AnalyzerContainer.botProgress
  )
  const slides = useSelector((state) => state.AnalyzerContainer.slides)
  const activeSlideIndex = useSelector(
    (state) => state.AnalyzerContainer.activeSlideIndex
  )
  const forceRender = useSelector(
    (state) => state.AnalyzerContainer.forceRender
  )
  const isConnectionAlive = useSelector(
    (state) => state.AnalyzerContainer.isConnectionAlive
  )
  const [message, setMessage] = useState(slides[activeSlideIndex].text)

  useEffect(() => {
    // when slide changes, update the message
    if (activeSlideIndex < slides.length) { setMessage(slides[activeSlideIndex].text) }
  }, [activeSlideIndex, forceRender])

  function handleFileUpload (upload_status,data){
    if (upload_status==="STARTED"){
      dispatch(UPDATE_BOT_PROGRESS(true))
    }
    else{
      dispatch(UPLOAD_FILE({data:data }))
      fileUploadCompleteCallback(data)
    }
  }
  function fileUploadCompleteCallback (data) {
    data.fileName = generateUniqueFileName(
      data.fileName,
      Object.keys(fileData)
    )
    getVisualization(
      { data: data.data, goal: {} },
      (response) => {
        dispatch(
          UPDATE_SUMMARY({ fileName: data.fileName, summary: response.summary })
        )
        const _message = {
          Visualization: response.text,
          Edit: '',
          active: 'Visualization'
        }
        dispatch(
          UPDATE_SLIDE({
            fileName: data.fileName,
            imageBlob: 'data:image/png;base64,' + response.raster,
            index: activeSlideIndex,
            text: _message,
            slideAction: 'CREATE',
            code: response.code
          })
        )
      },
      (error) => {
        dispatch(UPDATE_NETWORK_STATUS({ error }))
        console.log('error', error.message)
        
        if (error.name && error.name.includes("DATAFRAME_CONVERSION_ERROR")) {
          dispatch(DELETE_FILE({fileName:data.fileName}))
          setSnackbarData({ open: true, message: ERROR_MESSAGE.FILE_PROCESSING_ERROR })
        }
        else{
          setSnackbarData({ open: true, message: error.message })  
        }

      }
    )
  }
  function handleSelect (e) {
    dispatch(UPDATE_SELECTED_FILE({ fileName: e.target.value }))
  }
  function handlePromptTypeSelect (e) {
    setMessage({ ...message, active: e.target.value })
  }

  function handleKeyPress (event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevents the default form submission behavior
      onSendClick()
    }
  }
  function onSendClick () {
    if (!message[message.active]) return
    if (message[message.active].trim().length === 0) return
    let payload = {}
    let type = 'visualize'
    if (message.active === 'Visualization') {
      payload = {
        data: selectedData.data,
        goal: {
          question: message[message.active],
          visualization: message[message.active],
          rationale: ''
        }
      }
    } else if (message.active === 'Edit') {
      type = 'edit'
      // If fileName mismatch then warn user
      if (slides[activeSlideIndex].fileName !== selectedData.fileName) {
        setSnackbarData({
          open: true,
          message: ERROR_MESSAGE.INVALID_DATA_FOR_EDIT(
            slides[activeSlideIndex].fileName,
            selectedData.fileName
          )
        })
        return
      }

      payload = {
        instructions: [message[message.active]],
        code: slides[activeSlideIndex].code,
        data: selectedData.data
      }
    }
    dispatch(UPDATE_BOT_PROGRESS(true))

    getVisualization(
      payload,
      (data) => {
        dispatch(
          UPDATE_SUMMARY({
            fileName: selectedData.fileName,
            summary: data.summary
          })
        )
        dispatch(
          UPDATE_SLIDE({
            fileName: selectedData.fileName,
            imageBlob: 'data:image/png;base64,' + data.raster,
            index: activeSlideIndex,
            text: { Edit: '', ...message, active: 'Edit' },
            slideAction: 'UPDATE',
            code: data.code
          })
        )
      },
      (error) => {
        setSnackbarData({ open: true, message: error.message })
        dispatch(UPDATE_NETWORK_STATUS({ error }))
      },
      type
    )
  }
  const isUserQueryControlDisabled = botProgress || !selectedData
  const isSendButtonDisabled =
    isUserQueryControlDisabled || message[message.active].trim() === ''

  function onSlideChange (_activeSlideIndex, action) {
    if (action === 'CREATE') {
      // Create a new slide
      dispatch(
        UPDATE_SLIDE({
          fileName: selectedData ? selectedData.fileName : '',
          imageBlob: null,
          index: slides.length,
          text: {},
          slideAction: action
        })
      )
    } else if (action === 'UPDATE' && _activeSlideIndex < slides.length) {
      dispatch(NAVIGATE_SLIDE({ index: _activeSlideIndex, message }))
      const fileName = slides[_activeSlideIndex].fileName
      if (fileName !== '') {
        dispatch(
          UPDATE_SELECTED_FILE({
            fileName
          })
        )
      }
    } else if (action === 'DELETE' && _activeSlideIndex < slides.length) {
      dispatch(
        UPDATE_SLIDE({
          fileName: selectedData ? selectedData.fileName : '',
          imageBlob: null,
          index: _activeSlideIndex,
          text: {},
          slideAction: action
        })
      )
    }
  }
  const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarData({ open: false, message: '' })
  }
  function handleOnTextFieldChange (e) {
    setMessage({ ...message, [message.active]: e.target.value })
  }
  if (!selectedData) {
    return (
      <>
        <UploadFile handleFileUpload={handleFileUpload} noFilePresent={true} />
        <Backdrop className="backdrop" open={botProgress} sx={{ zIndex: 9 }}>
          <Paper>
            <BotProgress
              sx={{ position: 'absolute' }}
              text="is getting your plot"
            />
          </Paper>
        </Backdrop>
        <Snackbar
          open={snackbarData.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="error" onClose={handleSnackbarClose}>
            {snackbarData.message}
          </Alert>
        </Snackbar>
      </>
    )
  }
  return (
    <div className="Analyzer-container">
      <div className="data-container">
        <div className="select-data">
          <UploadFile handleFileUpload={handleFileUpload} />
          <TextField
            select
            size="small"
            id="demo-simple-select"
            value={selectedFile}
            onChange={handleSelect}
            sx={{
              flexGrow: '1',
              width: '190px',
              height: '60px',
              padding: '0px'
            }}
          >
            {Object.keys(fileData).map((data, index) => (
              <MenuItem value={fileData[data].fileName} key={index}>
                {fileData[data].fileName}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="data-summary">
          {selectedData?.summary === null &&
            (botProgress ? 'loading Summary...' : 'Error loading summary')}
          <TableContainer className="summary-table">
            <Table size="small" aria-label="simple table">
              {selectedData?.summary && (
                <TableBody>
                  {SUMMARY_TABLE_ROWS.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="sticky-cell">
                        {row.name} :{' '}
                      </TableCell>
                      {selectedData.summary.fields.map((field, index) => (
                        <TableCell key={index}>
                          {row.getCellValue(field)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="visualization">
        <Backdrop className="backdrop" open={botProgress} sx={{ zIndex: 9 }}>
          <Paper sx={{ padding: '1em', borderRadius: '30px' }}>
            <BotProgress
              sx={{ position: 'absolute' }}
              text=" is getting your plot"
            />
          </Paper>
        </Backdrop>
        <PresentationSidebar
          slideArray={slides}
          onSlideChange={onSlideChange}
          activeSlideIndex={activeSlideIndex}
        />

        <div className="presentation-container">
          <div className="send-prompt">
            <TextField
              select
              size="small"
              value={message.active}
              onChange={handlePromptTypeSelect}
              sx={{ flexGrow: '1', width: '190px', height: '40px' }}
              disabled={isUserQueryControlDisabled}
            >
              <MenuItem value={'Visualization'}>Visualization</MenuItem>
              {slides[activeSlideIndex].imageBlob && (
                <MenuItem value={'Edit'}>Edit</MenuItem>
              )}
            </TextField>
            <RichTextField
              style={{ height: '40px', lineHeight: '20px' }}
              placeholder={PLACEHOLDERS[message.active]}
              value={message[message.active] || ''}
              onChange={handleOnTextFieldChange}
              onKeyDown={handleKeyPress}
              disabled={isUserQueryControlDisabled}
              keywords={getMentionsObjectsFromArray(
                selectedData?.summary
                  ? selectedData.summary.fields.map((field) => field.column)
                  : []
              )}
            />
            <Button
              className="message-actions"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={onSendClick}
              disabled={isSendButtonDisabled}
              sx={{ width: 'fit-content' }}
            ></Button>
          </div>

          <Presentation
            slideArray={slides}
            activeSlideIndex={activeSlideIndex}
          />
        </div>
      </div>

      <Snackbar
        open={snackbarData.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" onClose={handleSnackbarClose}>
          {snackbarData.message}
        </Alert>
      </Snackbar>
      <SessionExpiredDialog isConnectionAlive={isConnectionAlive} />
    </div>
  )
}
