// React imports
import React, { useState, useEffect } from 'react'

// Component imports
import RenderMarkdown from '../RenderMarkdown/RenderMarkdown'
import EditNoteIcon from '@mui/icons-material/EditNote'
import Button from '@mui/material/Button'
import RichTextField from '../../RichTextField/RichTextField'

// Utility function import
import { formatMentionTextToHtml } from 'utils/helpers'

// Stylesheet import
import './t-viz.css'

const renderMarkdown = (response, type) => {
  if (response?.constructor === Array) {
    return response.map((value, index) => {
      if (typeof value === 'string') { return <RenderMarkdown key={index} markdown={value} /> }
      return value
    })
  }
  return <RenderMarkdown markdown={response} />
}

const Tviz = ({ text, type, id, editMessage, allowUserInteraction, isEditing }) => {
  const [message, setMessage] = useState(text)
  // sample markdown for debugging;
  // let markdown =
  //   "\n```python\nnum1 = 10 hello\nnum2 = 5\nsum = num1 + num2\nprint(f'The sum of {num1} and {num2} is {sum}')\n```";
  function handleSubmit () {
    editMessage({ id, type, message, action: 'submit' })
  }
  function handleCancel () {
    editMessage({ id, type, message, action: 'cancel' })
    // Revert to original message in UI
    setMessage(text)
  }
  function onEditClick () {
    editMessage({ id, type, message, action: 'edit' })
  }
  function handleKeyPress (event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevents the default form submission behavior
      handleSubmit()
    }
  }

  useEffect(() => {
    setMessage(text)
  }, [text])
  return (
    <div className="tiz-app" id={id}>
      <div className="text-response-display">
        {isEditing
          ? (
          <>
            <RichTextField
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={handleKeyPress}
            />
            <div className="buttons">
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={message.trim() === ''}
              >
                {type === 'user' ? 'Save & Submit' : 'Save'}
              </Button>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </>
            )
          : (
          <>
            {type.toLowerCase() === 'user'
              ? (
              <div className="user-message">
                {formatMentionTextToHtml(message)}
              </div>
                )
              : (
                  renderMarkdown(message, type)
                )}
            {allowUserInteraction && (
              <EditNoteIcon className="edit-icon" onClick={onEditClick} />
            )}
          </>
            )}
      </div>
    </div>
  )
}

export default Tviz
