import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Button from '@mui/material/Button'
import pptxgen from 'pptxgenjs'
import './Presentation.css'
import backgroundImage from '../../../assets/images/ppt_slide_white_bg.png'
export default function PresentationSidebar ({
  slideArray,
  onSlideChange,
  activeSlideIndex
}) {
  const generatePDF = () => {
    // eslint-disable-next-line new-cap
    const pptx = new pptxgen()
    slideArray.forEach((slide) => {
      const pptSlide = pptx.addSlide()

      if (slide.imageBlob) {
        pptSlide.addImage({
          data: slide.imageBlob,
          x: '10%',
          y: '10%',
          w: '80%',
          h: '80%'
        })
      }
      pptSlide.background = {
        path: backgroundImage
      }
    })
    pptx.writeFile({ fileName: 'Vina_plots.pptx' })
  }
  function getSlideClass (index) {
    const classList = ['slideList']
    if (index === activeSlideIndex) classList.push('slideList-active')
    if (slideArray.length === 1) classList.push('slideList-disable-delete')
    return classList.join(' ')
  }
  return (
    <div className="presentation-sidebar-container">
      <div className="slideList-container">
        {slideArray.map((slide, index) => {
          return (
            <div
              key={index}
              className={getSlideClass(index)}
              onClick={() => {
                onSlideChange(index, 'UPDATE')
              }}
            >
              {slide.imageBlob
                ? (
                <img src={slide.imageBlob} style={{ maxWidth: '90%' }} />
                  )
                : (
                <p></p>
                  )}
              <CloseOutlinedIcon
                className="SlideDeleteIcon"
                onClick={(e) => {
                  e.stopPropagation()
                  onSlideChange(index, 'DELETE')
                }}
              />
            </div>
          )
        })}
        {/* <div style={{ position: "fixed" }}>fullscreen</div> */}
      </div>
      <div className="button-container">
        <Button
          className="create-new-slide"
          variant="contained"
          onClick={() => onSlideChange(slideArray.length, 'CREATE')}
        >
          Create New Slide
        </Button>
        <Button variant="contained" onClick={(e) => generatePDF()}>
          Generate PPT
        </Button>
      </div>
    </div>
  )
}
