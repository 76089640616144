import React, { useRef, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import './UploadFile.css'
export default function UploadFile ({
  handleFileUpload,
  noFilePresent = false,
  supportedFileExtensions = []
}) {
  let fileInputRef = useRef(null)
  const processCSV = (file) => {
    const fileName = file.name
    const fileExtension = '.' + fileName.split('.').pop()
    if (!supportedFileExtensions.includes(fileExtension)) {
      window.alert('Invalid file type, Supported file types are ' + supportedFileExtensions.join(','))
      return
    }
    handleFileUpload('COMPLETED', file)
    fileInputRef.current.value = ''
  }
  const [over, setover] = useState(false)

  function getContainerClassName () {
    const classList = ['upload-container']
    if (over) classList.push('over')
    if (noFilePresent) classList.push('fullScreen')

    return classList.join(' ')
  }
  if (noFilePresent) {
    return (
      <div
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          processCSV(e.dataTransfer.files[0])
          setover(false)
        }}
        onDragOver={(e) => {
          e.preventDefault()
          setover(true)
        }}
        onDragLeave={(e) => {
          e.preventDefault()
          setover(false)
        }}
        className={getContainerClassName()}
      >
        <div className="drag-drop">
          <CloudUploadIcon sx={{ fontSize: '8em' }} />
          <h4>Drag and Drop Files or click anywhere to upload</h4>
          <label>
            <input
              id="files"
              ref={fileInputRef}
              accept={supportedFileExtensions.join(',')}
              type="file"
              onChange={(e) => processCSV(e.target.files[0])} // TODO: cant able to reupload the same file twice , fix it
            />
          </label>
        </div>
      </div>
    )
  }
  return (
    <form
      id="csv-form"
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()
        processCSV(e.dataTransfer.files[0])
        setover(false)
      }}
      onDragOver={(e) => {
        e.preventDefault()
        setover(true)
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        setover(false)
      }}
      className={'upload-container'}
    >
      <label style={{ flexGrow: 1, height: '40px' }}>
        UPLOAD FILE
        <input
          id="files"
          accept=".csv, .xlsx"
          type="file"
          onChange={(e) => processCSV(e.target.files[0])}
        />
      </label>
    </form>
  )
}
