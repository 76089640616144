// React imports
import React, { useEffect, useState } from 'react'

// Third-party library imports
import plotly from 'plotly.js/dist/plotly'
import PlotlyEditor from 'react-chart-editor'
import 'react-chart-editor/lib/react-chart-editor.css'

// Data and layout configuration imports
import {
  tableData,
  tableLayout,
  plotData,
  plotLayout
} from './viz_plotconfig'

// Material-UI imports
import Fab from '@mui/material/Fab'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp'

// Constants import
import { IS_MOBILE } from 'utils/Constants'

// Stylesheet import
import './viz.css'

// react-chart-editor DOM
let editorControlsInitialWidth
const config = {
  // editable: true,
  // staticPlot: true,
  // TODO Explore the below config that opens the chart in a new window with editable data
  // Refer https://plotly.com/javascript/configuration-options/
  // showLink: true,
  // plotlyServerURL: "https://chart-studio.plotly.com",
}

const Viz = ({
  messageId,
  chatClass,
  dataframe,
  plotType,
  plotConfig,
  vizSize,
  updatePlot,
  dataframeName
}) => {
  // Call when the props is changed
  const [data, setData] = useState([])
  const [layout, setLayout] = useState({})
  const [frames, setFrames] = useState([])
  useEffect(() => {
    let tempdata = []
    let templayout = {}
    const tempframes = []
    const isValidDataframe = Object.keys(dataframe).length > 0
    if (plotConfig.data && plotConfig.layout && plotConfig.frames) {
      // Add Dataframe rows to plot Data
      const dataCopy = deepCopy(plotConfig.data)
      dataCopy[0].cells.values = dataCopy[0].cells.valuesrc.map(
        (key, index) => dataframe[key]
      )
      setData(dataCopy)
      setLayout(deepCopy(plotConfig.layout))
      setFrames(deepCopy(plotConfig.frames))
      return
    }
    if (plotType === 'table' && isValidDataframe) {
      let dfKeys = plotConfig.columns || Object.keys(dataframe)
      dfKeys = dfKeys
        .filter(
          (key, index) => dataframe[key].filter((str) => str !== '').length > 0
        )
        .slice(0, 10)

      const dfValues = dfKeys.map(key => dataframe[key])

      tempdata = [...tableData]
      tempdata[0].cells.values = dfValues
      tempdata[0].cells.valuesrc = dfKeys
      tempdata[0].header.values = dfKeys
      tempdata[0].meta.columnNames.cells.values = dfKeys.join(' - ')

      templayout = { ...tableLayout }

      templayout.margin = { t: 30, b: 0, r: 10, l: 50 }
      // Dynamically calculate table cell height
      let plotHeight = 500
      if (vizSize === 'medium') plotHeight = 500
      else if (vizSize === 'medium height-reduced') plotHeight = 150
      templayout.height = plotHeight

      const numberOfCells = dfValues[0].length + 2 // including header and margin
      const cellHeight = Math.max(plotHeight / numberOfCells, 30)
      tempdata[0].cells.height = cellHeight
      tempdata[0].header.height = cellHeight
    } else if (
      ['scatter', 'line', 'histogram', 'bar', 'box', 'area'].includes(
        plotType
      ) &&
      isValidDataframe
    ) {
      const dfKeys = Object.keys(dataframe)
      const dfValues = dfKeys.map((key, index) => dataframe[key])

      tempdata = [...plotData]
      tempdata[0].type = plotType
      tempdata[0].cells.values = dfValues
      tempdata[0].cells.valuesrc = dfKeys
      tempdata[0].meta.columnNames.cells.values = dfKeys.join(' - ')
      tempdata[0].meta.columnNames.x = plotConfig.xColumn
      tempdata[0].meta.columnNames.y = plotConfig.yColumn
      tempdata[0].header.values = dfKeys
      tempdata[0].y = dataframe[plotConfig.yColumn]
      tempdata[0].ysrc = plotConfig.yColumn
      tempdata[0].x = dataframe[plotConfig.xColumn]
      tempdata[0].xsrc = plotConfig.xColumn
      tempdata[0].transforms[0].groupssrc = plotConfig.groupBy
      tempdata[0].transforms[0].groups = dataframe[plotConfig.groupBy]
      tempdata[0].transforms[0].meta.columnNames.groups = plotConfig.groupBy

      templayout = { ...plotLayout }
      templayout.xaxis.title.text = plotConfig.xColumn
      templayout.yaxis.title.text = plotConfig.yColumn
      templayout.title.text = plotType

      if (plotType === 'scatter') {
        tempdata[0].mode = 'markers'
      }
      if (plotType === 'area') {
        tempdata[0].stackgroup = 1
      }
    }

    setData(deepCopy(tempdata))
    setLayout(deepCopy(templayout))
    setFrames(deepCopy(tempframes))
  }, [plotConfig.plotIndex, plotConfig.stepIndex])

  useEffect(() => {
    const editorControls = document.querySelector(
      chatClass ? '.' + chatClass + ' .editor_controls' : '.editor_controls'
    )
    editorControlsInitialWidth = editorControls.offsetWidth
    window.dispatchEvent(new Event('resize'))
    toggleEditor(plotConfig.drawerIsOpen)
    // plotly_editor_initial_width = "1650px"; //since this plotly_editor.offsetWidth value changes when we jump between the panels
  }, [])
  const onPlotlyUpdate = React.useCallback((data, layout, frames) => {
    // set axis and title from data
    layout.title = { text: data[0].type }
    if (layout.xaxis) {
      if (data[0].meta.columnNames.x) { layout.xaxis.title = { text: data[0].meta.columnNames.x } } else layout.xaxis.title = { text: '' }
    }
    if (layout.yaxis) {
      if (data[0].meta.columnNames.y) { layout.yaxis.title = { text: data[0].meta.columnNames.y } } else layout.yaxis.title = { text: '' }
    }
    setLayout(deepCopy(layout))
    // Remove Dataframe rows from plot Data , Since it is large and should not be stored in redux store and local storage
    const dataCopy = deepCopy(data)
    dataCopy[0].cells.values = []
    updatePlot(
      {
        ...plotConfig,
        type: data[0].type,
        xColumn: data[0].meta.columnNames.x,
        yColumn: data[0].meta.columnNames.y,
        data: dataCopy,
        layout,
        frames
      },
      messageId
    )
  })
  function toggleEditor (drawerIsOpen) {
    const editorControls = document.querySelector(
      chatClass ? '.' + chatClass + ' .editor_controls' : '.editor_controls'
    )
    const svgContainer = document.querySelector(
      chatClass ? '.' + chatClass + ' .svg-container' : '.svg-container'
    )
    editorControls.style.width = drawerIsOpen
      ? '0px'
      : `${editorControlsInitialWidth}px`

    if (svgContainer) {
      svgContainer.style.overflow = 'hidden'
      svgContainer.style.width = '0px'
    }
  }
  function drawerControl () {
    toggleEditor(!plotConfig.drawerIsOpen)
    window.dispatchEvent(new Event('resize'))
    updatePlot(
      {
        ...plotConfig,
        drawerIsOpen: !plotConfig.drawerIsOpen
      },
      messageId
    )
  }
  function getDrawerStyle () {
    const plotlyEditor = document.querySelector(
      chatClass ? '.' + chatClass + ' .plotly_editor' : '.plotly_editor'
    )

    const TOP = plotlyEditor?.clientHeight / 2
    return {
      position: 'absolute',
      width: 30,
      height: 27,
      left: 0,
      top: TOP,
      zIndex: 1
    }
  }
  function getDatasourceOptions (dataframe) {
    return Object.keys(dataframe).map((name) => {
      return {
        value: name,
        label: name
      }
    })
  }
  const deepCopy = (data) => JSON.parse(JSON.stringify(data))
  plotConfig = deepCopy(plotConfig)

  return (
    <div className={getPlotSize(vizSize)}>
      {!IS_MOBILE && (
        <Fab color="primary" sx={getDrawerStyle()} onClick={drawerControl}>
          {!plotConfig.drawerIsOpen
            ? (
            <KeyboardArrowLeftSharpIcon />
              )
            : (
            <KeyboardArrowRightSharpIcon />
              )}
        </Fab>
      )}
      <PlotlyEditor
        data={data}
        layout={layout}
        config={config}
        frames={frames}
        dataSources={dataframe}
        dataSourceOptions={getDatasourceOptions(dataframe)}
        plotly={plotly}
        onUpdate={onPlotlyUpdate}
        useResizeHandler
        debug
        advancedTraceTypeSelector
      />
    </div>
  )
}

export default Viz

function getPlotSize (vizSize) {
  if (vizSize === 'full') {
    return 'viz-full-app'
  } else if (vizSize === 'medium') {
    return 'viz-medium-app'
  } else if (vizSize === 'medium height-reduced') {
    return 'viz-medium-app half-height'
  } else {
    return 'viz-full-app'
  }
}
