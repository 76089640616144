// React imports
import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

// Material-UI imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

// Material-UI Icons imports
import MessageIcon from '@mui/icons-material/Message'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import PrintIcon from '@mui/icons-material/Print'

// Component imports
import ExportChatDialog from 'component/PopUp/ExportChatDialog'

// Utility function import
import { extractMetaDataFromMentionsText, downloadJSON } from 'utils/helpers'
import { getChatExportData } from 'services/HttpAPIRequests'
// Stylesheet import
import './SideTabs.css'

export default function BasicChatList ({
  chatList,
  activeSession,
  onChatTabClick,
  onChatDeleteClick,
  handlePrint
}) {
  const [isExportChatDialogOpen, setIsExportChatDialogOpen] = useState(false)

  const history = useSelector(
    (state) => state.ChatBaseContainer.sessions[activeSession].history
  )

  const displayChatList = (chatList) => {
    return chatList.map((session) => {
      const getSessionName = (length) => {
        const { text } = extractMetaDataFromMentionsText(session.name)
        const sessionName = text.slice(0, length)
        return sessionName.length < length ? sessionName : sessionName + '...'
      }

      if (session.id !== activeSession) {
        return (
          <ListItem
            disablePadding
            id="side-tabs-disable-line-height"
            onClick={() => onChatTabClick(session.id)}
            key={session.id}
          >
            <ListItemButton>
              <ListItemIcon id="side-tabs-adjust-min-width">
                <MessageIcon />
              </ListItemIcon>
              <ListItemText
                id="side-tabs-disable-child-line-height"
                primary={getSessionName(16)}
              />
            </ListItemButton>
          </ListItem>
        )
      } else {
        return (
          <ListItem disablePadding key={session.id}>
            <ListItemButton selected style={{ color: '#1976d2' }}>
              <ListItemIcon id="side-tabs-adjust-min-width">
                <MessageIcon htmlColor="#1976d2" />
              </ListItemIcon>
              <ListItemText
                id="side-tabs-disable-child-line-height"
                primary={getSessionName(10)}
              />
              {history.length > 0 &&
              <PrintIcon
                onClick={() => setIsExportChatDialogOpen(true)}
                className="print-icon"
              />
              }
              {chatList.length > 1 && (
                <CloseOutlinedIcon
                  id="side-tabs-close-icon"
                  htmlColor="#d63f3f"
                  onClick={() => onChatDeleteClick(session.id)}
                />
              )}
            </ListItemButton>
          </ListItem>
        )
      }
    })
  }
  function handleExportChatDialogClose () {
    setIsExportChatDialogOpen(false)
  }
  function handleExport (exportType) {
    if (exportType === 'PDF') {
      handlePrint()
    } else if (exportType === 'ipynb') {
      const payload = {
        type: exportType,
        chatHistory: history.map((message) => {
          // convert mentions to plain text
          if (message.name === 'user') {
            const { text } = extractMetaDataFromMentionsText(message.value)
            return { ...message, value: text }
          }
          return message
        })
      }
      getChatExportData(payload, (data) => {
        downloadJSON(data, 'ChatNotebook.ipynb')
      })
    }
    handleExportChatDialogClose()
  }
  return (
    <div id="side-tabs-base">
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <nav aria-label="main mailbox folders">
          <List disablePadding>{displayChatList(chatList)}</List>
        </nav>
      </Box>
      <ExportChatDialog
        open={isExportChatDialogOpen}
        onClose={handleExportChatDialogClose}
        handleExport={handleExport}
      />
    </div>
  )
}
