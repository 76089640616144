// React import
import React from 'react'
// Component import
import UploadFile from 'component/Analyzer/UploadFile/UploadFile.js'
// Stylesheet import
import './uploadPDF.css'

export default function UploadPDF ({ handleFileUpload}) {
  return (
    <div className='upload-pdf-container'>
      <UploadFile handleFileUpload={handleFileUpload} noFilePresent={true} supportedFileExtensions={[".pdf"]} />
    </div>
  )
}