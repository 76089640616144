// SideBarSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import {
  stepsWithCachedData,
  generateUniqueFileName,
  getAttachmentsFromHistory
} from 'utils/helpers'
import { IS_MOBILE, CUSTOM_VINA_MESSAGE } from 'utils/Constants'
const attachmentsInitialState = { selected: [], attachments: {} }
const chatPanelInitialState = {
  createdAt: 0,
  sessionName: 'New chat',
  history: [],
  botProgress: false,
  responseStream: false,
  ignoredDataSources: [],
  attachments: attachmentsInitialState,
  datasheet:{
    blob_file_url: "",
    collection_name: "",
    datasheet_id:"",
    file_name:"",
    isUploading:false,
    status: {
      value: "",
      message: ""
    },
    isOpen:true,
    currentPageNumber:1,
    totalPages:0,
    highlightText:"",
    nodes: []
  }

}
const deepCopy = (data) => JSON.parse(JSON.stringify(data))
let initialState = {
  activeSession: 'initialSession',
  sessions: {
    initialSession: chatPanelInitialState
  },
  // properties that are common for all sessions
  datasourceDetails: [],
  aboutContent: '',
  isChatDrawerOpen: !IS_MOBILE, // close the drawer by default in mobile devices
  isConnectionAlive: true,
  userName: '',
}
const chatData = localStorage.getItem('chatData')
if (chatData) {
  const parsedChatData = JSON.parse(chatData)

  // reset botProgress and responseStream
  for (const sessionID in parsedChatData.sessions) {
    const session = parsedChatData.sessions[sessionID]
    const history = session.history
    if (session.botProgress || session.responseStream) {
      // If responseStream or botProgress is true, change the last message to custom message
      const lastMessage = history[history.length - 1]
      const customBotMessage = {
        name: 'Vina',
        id: uuidv4(),
        value: { answer: CUSTOM_VINA_MESSAGE.PROCESSING_ERROR, steps: [] }
      }
      if (lastMessage.name === 'Vina') {
        history[history.length - 1] = customBotMessage
      } else {
        history.push(customBotMessage)
      }
    }
    session.botProgress = false
    session.responseStream = false

    for (let i = 0; i < history.length; i++) {
      const message = history[i]
      if (message.name === 'user') {
        if (!message.selectedAttachments) {
          history[i].selectedAttachments = []
          continue
        }
        message.selectedAttachments = message.selectedAttachments.filter(
          (attachment) =>
            Object.keys(session.attachments.attachments).includes(attachment)
        )
      }
    }
    if (!session.attachments?.attachments) { session.attachments = attachmentsInitialState }
    session.attachments.attachments = {
      ...session.attachments.attachments,
      ...getAttachmentsFromHistory(session.history, session.attachments)
    }
    session.attachments.selected = session.attachments.selected.filter(
      (attachment) =>
        Object.keys(session.attachments.attachments).includes(attachment)
    )
  }

  initialState = {
    ...initialState,
    ...parsedChatData
  }
}
const ChatBaseContainerSlice = createSlice({
  name: 'ChatPanel',
  initialState,

  reducers: {
    UPDATE_USER_HISTORY: (state, action) => {
      
      const { sessionID, question, selectedAttachments } = action.payload

      if (!state.sessions[sessionID]) {
        // If session does not exist, return the original state
        return state
      }

      const updatedSessions = {
        ...state.sessions,
        [sessionID]: {
          ...state.sessions[sessionID],
          history: [
            ...state.sessions[sessionID].history,
            {
              id: uuidv4(),
              name: 'user',
              value: question,
              selectedAttachments
            }
          ],
          botProgress: true,
          attachments: {
            ...state.sessions[sessionID].attachments,
            selected: []
          }
        }
      }

      return {
        ...state,
        sessions: updatedSessions
      }
    },
    UPDATE_CONNECTION_STATE: (state, action) => {
      const { isConnectionAlive } = action.payload

      return {
        ...state,
        isConnectionAlive
      }
    },
    UPDATE_EXPERIMENTAL_SETTINGS: (state, action) => {
      const { experimentalSettings } = action.payload
      const _state = { ...state }
      _state.experimentalSettings = experimentalSettings
      return {
        ..._state
      }
    },
    UPDATE_ABOUT_CONTENT: (state, action) => {
      const { aboutContent } = action.payload

      return {
        ...state,
        aboutContent
      }
    },
    UPDATE_USER_NAME: (state, action) => {
      const { userName } = action.payload

      return {
        ...state,
        userName
      }
    },
    UPDATE_BOT_FEEDBACK_STATE: (state, action) => {
      const { sessionID, data } = action.payload

      if (!state.sessions[sessionID]) {
        // If session does not exist, return the original state
        return state
      }

      const updatedHistory = [...state.sessions[sessionID].history]
      updatedHistory[data.index] = {
        ...updatedHistory[data.index],
        feedbackState: data.feedbackState
      }

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...state.sessions[sessionID],
            history: updatedHistory
          }
        }
      }
    },

    UPDATE_BOT_HISTORY: (state, action) => {
      const { sessionID, data } = action.payload

      const activeSessionObject = state.sessions[sessionID]
      if (!activeSessionObject) {
        return state
      }
      const datasheet = deepCopy(activeSessionObject.datasheet)
      datasheet.nodes = data.source_nodes
      if (data.source_nodes?.length > 0) {
        if (data.source_nodes[0].page)
        datasheet.currentPageNumber = data.source_nodes[0].page
        if (data.source_nodes[0].text)
        datasheet.highlightText = data.source_nodes[0].highlighted_text

      }

      const updatedHistory = deepCopy(activeSessionObject.history)
      const botmessage = {
        name: 'Vina',
        id: uuidv4(),
        value: {
          ...data,
          steps: stepsWithCachedData(data),
          responseTime: data.time_taken,
          feedbackState: ''
        }
      }
      // make attachment file names unique
      for (const step of botmessage.value.steps) {
        if (step.attachment?.data) {
          step.attachment.name = generateUniqueFileName(
            step.attachment.name,
            Object.keys(
              getAttachmentsFromHistory(
                updatedHistory,
                activeSessionObject.attachments
              )
            )
          )
        }
      }

      let lastMessage =
        activeSessionObject.history[activeSessionObject.history.length - 1]
      if (lastMessage.name === 'user') {
        updatedHistory.push(botmessage)
      }
      else if (lastMessage.name === 'Vina') {
        if (lastMessage.value.answer === '') {
          // Update the lastmessage
          updatedHistory[updatedHistory.length - 1] = botmessage
        } else {
          console.error('already got an AI response', data)
          return state
        }
      }
      lastMessage = updatedHistory[updatedHistory.length - 1]
      const attachments = deepCopy(activeSessionObject.attachments)
      attachments.attachments = getAttachmentsFromHistory(
        updatedHistory,
        attachments
      )

      attachments.selected = attachments.selected.filter((attachment) =>
        Object.keys(attachments.attachments).includes(attachment)
      )
      for (const step of lastMessage.value.steps) {
        if (step.attachment?.data) {
          // Since data is stored in Session , remove in steps
          step.attachment.data = null
        }
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...activeSessionObject,
            history: updatedHistory,
            responseStream: botmessage.value.answer === '',
            botProgress: false,
            attachments,
            datasheet
          }
        }
      }
    },
    UPDATE_ATTACHMENTS: (state, action) => {
      const { sessionID, attachments } = action.payload
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...state.sessions[sessionID],
            attachments
          }
        }
      }
    },
    TRIM_BOT_HISTORY: (state, action) => {
      const { sessionID, messageID } = action.payload

      const activeSessionObject = state.sessions[sessionID]
      if (!activeSessionObject) {
        return state
      }
      const updatedHistory = []
      for (const message of activeSessionObject.history) {
        if (message.id === messageID) break
        updatedHistory.push(message)
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...activeSessionObject,
            history: updatedHistory
          }
        }
      }
    },
    EDIT_MESSAGE: (state, action) => {
      const { sessionID, messageID, editAction } = action.payload

      const activeSessionObject = state.sessions[sessionID]
      if (!activeSessionObject) {
        return state
      }
      const updatedHistory = deepCopy(activeSessionObject.history)
      const isEditing = editAction === 'edit'
      for (const message of updatedHistory) {
        if (message.id === messageID) {
          message.isEditing = isEditing
        } else {
          // only one message should be edited at a time in a session
          message.isEditing = false
        }
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...activeSessionObject,
            history: updatedHistory
          }
        }
      }
    },
    UPDATE_PLOT: (state, action) => {
      const { sessionID, plotData, messageId } = action.payload
      const plotIndex = plotData.plotIndex
      const stepIndex = plotData.stepIndex
      const activeSessionObject = state.sessions[sessionID]
      if (!activeSessionObject) {
        return state
      }
      const updatedHistory = activeSessionObject.history.map((message) => {
        if (message.id === messageId) {
          const messageCopy = deepCopy(message)
          if (plotData.plotAction) {
            const step = messageCopy.value.steps[stepIndex]
            if (plotData.plotAction === 'Create') {
              plotData.plotAction = ''

              // Create a new visualization object
              const updatedVisualization = deepCopy({
                ...plotData,
                id: uuidv4()
              })

              // Use unshift to add the new visualization at the beginning of the array
              step.visualizationCache.unshift(updatedVisualization)
            }
            if (plotData.plotAction === 'Delete') {
              plotData.plotAction = ''
              // Remove the plot from the visualizationCache
              step.visualizationCache.splice(plotIndex, 1)
            }
            // Update the plotIndex for all visualizations in the cache
            step.visualizationCache.forEach((visualization, index) => {
              visualization.plotIndex = index
            })
          } else {
            messageCopy.value.steps[stepIndex].visualizationCache[plotIndex] =
              deepCopy(plotData)
          }
          return messageCopy
        }
        return message
      })

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...activeSessionObject,
            history: updatedHistory
          }
        }
      }
    },

    CREATE_SESSION: (state, action) => {
      console.log('CREATE_SESSION')
      const newSession = uuidv4()
      const currentDate = new Date()
      const currentTime = currentDate.getTime()
      const nonRequiredDataSources = state.datasourceDetails.filter(
        (item) => !item.required
      )
      const ignoredDataSources = nonRequiredDataSources.map(
        (item) => item.name
      )

      const newChatPanelInitialState = {
        ...chatPanelInitialState,
        createdAt: currentTime,
        ignoredDataSources
      }

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [newSession]: newChatPanelInitialState
        },
        activeSession: newSession
      }
    },

    DELETE_SESSION: (state, action) => {
      const sessionToDelete = action.payload
      const sessions = { ...state.sessions }

      if (Object.keys(sessions).length === 1) {
        return state
      }

      delete sessions[sessionToDelete]

      const lastSession = Object.keys(sessions).reduce((prev, current) => {
        return sessions[current].createdAt > sessions[prev].createdAt
          ? current
          : prev
      })
      return {
        ...state,
        sessions,
        activeSession: lastSession
      }
    },

    UPDATE_ACTIVE_SESSION: (state, action) => {
      const activeSession = action.payload

      return {
        ...state,
        activeSession
      }
    },
    UPDATE_SESSION_NAME: (state, action) => {
      const sessionName = action.payload

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [state.activeSession]: {
            ...state.sessions[state.activeSession],
            sessionName
          }
        }
      }
    },

    UPDATE_DATASOURCE_DETAILS: (state, action) => {
      const newDatasourceDetails = action.payload
      const nonRequiredDataSources = newDatasourceDetails.filter(
        (item) => !item.required
      )

      const updatedSessions = Object.keys(state.sessions).reduce(
        (updated, sessionID) => {
          const session = state.sessions[sessionID]
          if (
            session.ignoredDataSources.length === 0 &&
            session.history.length === 0
          ) {
            updated[sessionID] = {
              ...session,
              ignoredDataSources: nonRequiredDataSources.map(
                (item) => item.name
              )
            }
          } else {
            updated[sessionID] = session
          }
          return updated
        },
        {}
      )

      return {
        ...state,
        datasourceDetails: newDatasourceDetails,
        sessions: updatedSessions
      }
    },

    UPDATE_IGNORED_DATASOURCES: (state, action) => {
      const { sessionID, data } = action.payload

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...state.sessions[sessionID],
            ignoredDataSources: data
          }
        }
      }
    },
    UPDATE_CHAT_DRAWER_STATE: (state, action) => {
      return {
        ...state,
        isChatDrawerOpen: action.payload
      }
    },
    UPDATE_DATASHEET: (state, action) => {
      const { sessionID, data } = action.payload

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [sessionID]: {
            ...state.sessions[sessionID],
            datasheet: data
          }
        }
      }
    }
  }
})

export const {
  UPDATE_USER_HISTORY,
  UPDATE_BOT_HISTORY,
  UPDATE_PLOT,
  UPDATE_BOT_FEEDBACK_STATE,
  UPDATE_IGNORED_DATASOURCES,

  CREATE_SESSION,
  DELETE_SESSION,
  UPDATE_ACTIVE_SESSION,
  UPDATE_SESSION_NAME,

  UPDATE_DATASOURCE_DETAILS,
  TRIM_BOT_HISTORY,
  EDIT_MESSAGE,
  UPDATE_CHAT_DRAWER_STATE,
  UPDATE_CONNECTION_STATE,
  UPDATE_ABOUT_CONTENT,
  UPDATE_USER_NAME,
  UPDATE_ATTACHMENTS,
  UPDATE_EXPERIMENTAL_SETTINGS,
  UPDATE_DATASHEET
} = ChatBaseContainerSlice.actions
export default ChatBaseContainerSlice.reducer
