import React, { useState, useEffect } from 'react'
import { TextField, Autocomplete, MenuItem } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import Chip from '@mui/material/Chip'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'

export default function MultiSelect ({
  attachments,
  onAttachmentUpdate,
  scope
}) {
  const [selectedOptions, setSelectedOptions] = useState(attachments.selected)
  useEffect(() => {
    setSelectedOptions(attachments.selected)
  }, [attachments.selected])
  function onAttachmentChange (e, values) {
    setSelectedOptions(values)
    onAttachmentUpdate({ ...attachments, selected: values })
  }
  const _style = {
    message: {
      width: '100%',
      pointerEvents: 'none'
    },
    messageEdit: { width: '100%' },
    session: { position: 'fixed', bottom: 75, width: '100%', zIndex: 1 }
  }
  const containerStyle = {
    m: 1,
    width: 'calc(100% - 200px)',
    marginLeft: 0,
    backgroundColor: '#fafafa',
    '& .MuiAutocomplete-inputRoot:before,.MuiAutocomplete-inputRoot:hover:before':
      {
        borderBottom: '0px'
      }
  }
  if (scope.includes('message')) {
    containerStyle.width = '100%'
    containerStyle['& .MuiFilledInput-root'] = {
      paddingTop: 0,
      backgroundColor: '#f2f4f7'
    }
    if (!scope.includes('Edit')) {
      containerStyle['& .MuiChip-deleteIcon'] = {
        display: 'none'
      }
    }
    containerStyle['& .MuiAutocomplete-tag'] = {
      height: '25px'
    }
    containerStyle.margin = 0
  }
  const dataTypeTagStyleMap = {
    DataFrame: {
      backgroundColor: '#1976d2',
      color: 'white',
      '& .MuiChip-deleteIcon': {
        color: '#dadada'
      },
      '& .MuiChip-deleteIcon:hover': {
        color: '#eeeeee'
      }
    },
    link: { backgroundColor: '#cccccc' }
  }
  return (
    <div style={_style[scope]}>
      <Autocomplete
        sx={containerStyle}
        value={selectedOptions}
        onChange={onAttachmentChange}
        multiple
        options={Object.keys(attachments.attachments)}
        getOptionLabel={(option) => option.toString()}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              key={index}
              icon = {<TableChartOutlinedIcon className='data-icon' color="gray" sx = {{ scale: '0.85' }}/>}
              sx={dataTypeTagStyleMap[attachments.attachments[option]?.type]}
              {...getTagProps({ index })}
              label={option}
            />
          ))
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, endAdornment: null }}
            variant="filled"
            label={scope === 'session' ? 'Select Attachments' : ''}
            placeholder={scope === 'session' ? 'Type the Attachment name' : ''}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <MenuItem
            {...props}
            key={option}
            value={option}
            sx={{ justifyContent: 'space-between' }}
          >
            <TableChartOutlinedIcon className='data-icon' color="black" sx={{ paddingRight: '5px' }}/>
            {option}
            {selected ? <CheckIcon color="info" /> : null}
          </MenuItem>
        )}
      />
      {/* <FileUploadIcon
        sx={{
          position: "fixed",
          right: "10px",
          bottom: "100px",
          color: "darkgray",
          cursor: "pointer",
          ":hover": {
            color: "gray",
          },
        }}
      /> */}
    </div>
  )
}
