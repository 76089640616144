// React imports
import React from 'react'
import { useSelector } from 'react-redux'

// Third-party library imports
import { Mention, MentionsInput } from 'react-mentions'

// Utility function imports
import { querySuggestionsData } from 'utils/helpers'

// Constants import
import { IS_MOBILE } from 'utils/Constants'

// Styles and stylesheet imports
import mentionsInputStyle from './mentionsInputStyle'
import './RichTextField.css'

const RichTextField = (props) => {
  // Get ignored dataSources from store to filter keywords
  const activeSession = useSelector(
    (state) => state.ChatBaseContainer.activeSession
  )
  const ignoredDataSources = useSelector(
    (state) =>
      state.ChatBaseContainer.sessions[activeSession].ignoredDataSources
  )

  return (
    <MentionsInput
      className="template-input"
      value={props.value}
      onChange={props.onChange}
      style={{ ...mentionsInputStyle, ...props.style }}
      placeholder={props.placeholder}
      inputRef={props.inputRef}
      disabled={props.disabled}
      onKeyDown={props.onKeyDown}
      allowSuggestionsAboveCursor={true}
      allowSpaceInQuery={true}
    >
      <Mention
        trigger="@"
        className="mention-text"
        data={(query) =>
          querySuggestionsData(query, ignoredDataSources, props.keywords)
        }
        renderSuggestion={(
          suggestion,
          search,
          highlightedDisplay,
          index,
          focused
        ) => {
          return (
            <div className="suggestion-item">
              <span className="suggestion-keyword">{highlightedDisplay}</span>
              {!IS_MOBILE && ( // hide category on mobile devices
                <span className="suggestion-category">
                  {suggestion.category}
                </span>
              )}
            </div>
          )
        }}
      />
    </MentionsInput>
  )
}

export default RichTextField
