import { createSlice } from '@reduxjs/toolkit'
const initialSlideText = {
  Visualization: '',
  Edit: '',
  active: 'Visualization'
}
const AnalyzerContainerSlice = createSlice({
  name: 'AnalyzerContainer',
  initialState: {
    forceRender: 0,
    activeSlideIndex: 0,
    selectedFile: '',
    fileData: {},
    slides: [
      {
        fileName: '',
        text: initialSlideText,
        imageBlob: null,
        code: null
      }
    ],
    isConnectionAlive: true,
    botProgress: false
  },

  reducers: {
    UPLOAD_FILE: (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        selectedFile: data.fileName,
        botProgress: true,
        fileData: {
          ...state.fileData,
          [data.fileName]: {
            ...data,
            summary: null
          }
        }
      }
    },
    DELETE_FILE: (state, action) => {
      const { fileName } = action.payload;
      const updatedFileData = { ...state.fileData };
      delete updatedFileData[fileName];
      return {
        ...state,
        selectedFile: Object.keys(updatedFileData)[0] || '',
        fileData: updatedFileData,
      };
    },
    UPDATE_SELECTED_FILE: (state, action) => {
      const { fileName } = action.payload
      return {
        ...state,
        selectedFile: fileName
      }
    },
    UPDATE_BOT_PROGRESS: (state, action) => {
      const botProgress = action.payload
      return {
        ...state,
        botProgress
      }
    },
    NAVIGATE_SLIDE: (state, action) => {
      const { index, message } = action.payload
      if (index > state.slides.length) return state
      const _slides = JSON.parse(JSON.stringify(state.slides))
      _slides[state.activeSlideIndex].text = {
        ...initialSlideText,
        ...message
      }
      return {
        ...state,
        activeSlideIndex: index,
        slides: _slides
      }
    },
    UPDATE_SUMMARY: (state, action) => {
      // TODO: Remove this reducer
      const { fileName, summary } = action.payload
      if (!summary) return state
      return {
        ...state,
        fileData: {
          ...state.fileData,
          [fileName]: {
            ...state.fileData[fileName],
            summary
          }
        }
      }
    },
    UPDATE_NETWORK_STATUS: (state, action) => {
      const { error } = action.payload
      return {
        ...state,
        isConnectionAlive: error.isConnectionAlive,
        botProgress: false
      }
    },
    UPDATE_SLIDE: (state, action) => {
      const { fileName, imageBlob, index, text, slideAction, code } =
        action.payload
      const _slides = JSON.parse(JSON.stringify(state.slides))
      let forceRender = state.forceRender
      let activeSlideIndex = state.activeSlideIndex
      let _index = index

      if (slideAction === 'DELETE') {
        _slides.splice(index, 1)
        if (index === 0) _index = 0
        else _index = index - 1
      } else {
        if (slideAction === 'CREATE' && imageBlob !== null) {
          if (
            index === undefined ||
            index >= _slides.length ||
            index < 0 ||
            _slides[_index].imageBlob !== null
          ) {
            // if index is out of bounds or if the slide has already an image, set to last slide
            _index = _slides.length - 1
            if (_slides[_index].imageBlob !== null) {
              // if there is content in last slide , create new slide
              _index = _slides.length
            }
          }
        }
        _slides[_index] = {
          imageBlob,
          text: { ...initialSlideText, ...text },
          fileName,
          code
        }
      }
      if (_index === activeSlideIndex) forceRender += 1
      activeSlideIndex = _index

      return {
        ...state,
        forceRender,
        botProgress: false,
        activeSlideIndex,
        slides: _slides
      }
    }
  }
})

export const {
  UPLOAD_FILE,
  DELETE_FILE,
  UPDATE_SUMMARY,
  UPDATE_SELECTED_FILE,
  UPDATE_SLIDE,
  NAVIGATE_SLIDE,
  UPDATE_NETWORK_STATUS,
  UPDATE_BOT_PROGRESS
} = AnalyzerContainerSlice.actions
export default AnalyzerContainerSlice.reducer
