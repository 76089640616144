const tableData = [
  {
    type: 'table',
    mode: 'markers',
    cells: {
      values: [], // Update the Values
      valuesrc: [] // Update the header name
    },
    meta: {
      columnNames: {
        cells: {
          values: ''
        },
        header: {
          values: ''
        }
      }
    },
    header: {
      values: [], // Update the header name
      fill: { color: 'rgb(25, 118, 210)' },
      font: { color: 'rgb(255, 255, 255)' }
    }
  }
]

const tableLayout = {
  xaxis: {
    range: [-1, 6],
    autorange: true
  },
  yaxis: {
    range: [-1, 4],
    autorange: true
  },
  autosize: true,
  title: {
    text: 'Tabular Data'
  }
}

const plotData = [
  {
    cells: {
      values: [],
      valuesrc: []
    },
    meta: {
      columnNames: {
        cells: {
          values: ''
        },
        header: {
          values: ''
        },
        x: '',
        y: ''
      }
    },
    header: {
      values: [],
      fill: { color: 'rgb(25, 118, 210)' },
      font: { color: 'rgb(255, 255, 255)' }
    },
    y: [],
    ysrc: '',
    x: [],
    xsrc: '',
    transforms: [
      {
        type: 'groupby',
        groupssrc: '',
        groups: [],
        meta: {
          columnNames: {
            groups: ''
          }
        }
      }
    ]
  }
]

const plotLayout = {
  xaxis: {
    autorange: true,
    title: {
      text: ''
    }
  },
  yaxis: {
    autorange: true,
    title: {
      text: ''
    }
  },
  autosize: true,
  title: {
    text: ''
  },
  mapbox: {
    style: 'open-street-map'
  },
  showlegend: true
}

export { tableData, tableLayout, plotLayout, plotData }
