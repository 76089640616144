import { configureStore } from '@reduxjs/toolkit'
import ChatBaseReducer from '../container/ChatBaseContainer/ChatBaseContainerSlice'
import AnalyzerReducer from '../container/AnalyzerContainer/AnalyzerContainerSlice'
import { storeDataVersion } from '../utils/Constants'
import { updateStoreData } from '../utils/helpers'
updateStoreData(storeDataVersion)
let isLocalStorageExceededWarningDisplayed = false
const localStorageMiddleware = (store) => (next) => (action) => {
  const prevState = store.getState()
  const result = next(action)
  const nextState = store.getState()

  // Check if ChatBaseContainer has been updated
  if (prevState.ChatBaseContainer !== nextState.ChatBaseContainer) {
    try {
      localStorage.setItem(
        'chatData',
        JSON.stringify({
          activeSession: nextState.ChatBaseContainer.activeSession,
          sessions: nextState.ChatBaseContainer.sessions,
          experimentalSettings : nextState.ChatBaseContainer.experimentalSettings
        })
      )
    } catch {
      // TODO : show the alert message in a Dialog like Session Expired message
      if (!isLocalStorageExceededWarningDisplayed) {
        const localStorageDetails = window.getLocalStorageUsage()
        const unit = localStorageDetails.unit
        alert(
          `LocalStorage limit exceeded !!! 
Total : ${localStorageDetails.total} ${unit}
Used  : ${localStorageDetails.used} ${unit}
Free  : ${localStorageDetails.free} ${unit}
Your upcoming chats will not be retained on page reload
Delete other chats to free up space
          `
        )
        isLocalStorageExceededWarningDisplayed = true
      }
    }
  }

  return result
}

export const store = configureStore({
  reducer: {
    ChatBaseContainer: ChatBaseReducer,
    AnalyzerContainer: AnalyzerReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware)
})
