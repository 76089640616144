import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import SettingsIcon from '@mui/icons-material/Settings'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import '../ExperimentalSettings/ExperimentalSettings.css'

import {
  UPDATE_EXPERIMENTAL_SETTINGS
  } from '../ChatBaseContainer/ChatBaseContainerSlice'
import { useDispatch, useSelector } from 'react-redux'

export default function ExperimentalSettings () {
  const defaultConfig = useSelector(
    (state) => state.ChatBaseContainer.experimentalSettings
  )
  const [open, setOpen] = React.useState(false)
  const [isFollowBackQuestionAllowed, setIsFollowBackQuestionAllowed] = React.useState(false)
  const [isHighlightRetreivedNodes, setIsHighlightRetreivedNodes] = React.useState(false)
  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true)
  }
  useEffect(() => {
    const defaultExperimentalSettings = {
      isFollowBackQuestionAllowed: false,
      isHighlightRetreivedNodes: false
    }
    const storedExperimentalSettings = JSON.parse(localStorage.getItem('chatData'))?.experimentalSettings
    console.log(storedExperimentalSettings)
    if (storedExperimentalSettings) {
      setIsFollowBackQuestionAllowed(storedExperimentalSettings.isFollowBackQuestionAllowed)
      setIsHighlightRetreivedNodes(storedExperimentalSettings.isHighlightRetreivedNodes)
      dispatch(
        UPDATE_EXPERIMENTAL_SETTINGS({experimentalSettings: storedExperimentalSettings })
      )
    } else if (defaultConfig === null || defaultConfig === undefined) {
      dispatch(
        UPDATE_EXPERIMENTAL_SETTINGS({experimentalSettings: defaultExperimentalSettings })
      )
    }
  }, [])

  const handleClose = () => {
    console.log(defaultConfig)
    if (defaultConfig) {
      setIsFollowBackQuestionAllowed(defaultConfig.isFollowBackQuestionAllowed)
      setIsHighlightRetreivedNodes(defaultConfig.isHighlightRetreivedNodes)
      setOpen(false)
    } else {
      setOpen(false)
      setIsFollowBackQuestionAllowed(false)
      setIsHighlightRetreivedNodes(false)
    }
  }

  const handleSave = () => {
    setOpen(false)
    const updatedExperimentalSettings = {
      isFollowBackQuestionAllowed,
      isHighlightRetreivedNodes
    }
    dispatch(
      UPDATE_EXPERIMENTAL_SETTINGS({ experimentalSettings: updatedExperimentalSettings })
    )
  }

  const handleSwitchFollowbackQuestion = (event) => {
    setIsFollowBackQuestionAllowed(event.target.checked)
  }
  const handleSwitchChangeHighlight = (event) => {
    setIsHighlightRetreivedNodes(event.target.checked)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  return (
    <React.Fragment>
      <SettingsIcon className='settings-icon' onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Vina's settings"}
        </DialogTitle>

        <DialogContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <DialogContentText id="alert-dialog-description">
                <span>
                    Ask for follow back question (Experimental setting)
                </span>
                </DialogContentText>
                <Switch {...label} checked={isFollowBackQuestionAllowed} onChange={handleSwitchFollowbackQuestion} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <DialogContentText id="alert-dialog">
                <span>
                Highlight the source for the response (Experimental setting)
                </span>
                </DialogContentText>
                <Switch {...label} checked={isHighlightRetreivedNodes} onChange={handleSwitchChangeHighlight} />
            </Box>
            </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSave} style={{
            backgroundColor: 'rgb(25,118,201)',
            color: 'white'
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
