import React from 'react'
import './Presentation.css'

export default function Presentation ({ activeSlideIndex, slideArray }) {
  return (
    <>
      <div className="slide">
        {slideArray[activeSlideIndex].imageBlob
          ? (
          <img
            src={slideArray[activeSlideIndex].imageBlob}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
            )
          : (
          <h3></h3>
            )}
      </div>
    </>
  )
}
