// React import
import * as React from 'react'

// Material-UI imports
import Checkbox from '@mui/material/Checkbox'

// Material-UI Icons imports
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

export default function LikeDislikeCheckboxes (props) {
  const checkState = props.checkState
  const onLikeChangeEvent = props.onLikeChangeEvent
  const onDislikeChangeEvent = props.onDislikeChangeEvent
  let likeState = false
  let dislikeState = false

  if (checkState === 'like') {
    likeState = true
    dislikeState = false
    return (
      <div>
        <Checkbox
          {...label}
          icon={<ThumbUpOffAltIcon htmlColor="#AAC8C7" />}
          checkedIcon={<ThumbUpAltIcon htmlColor="#34ba96" />}
          checked={likeState}
          title="like"
          disabled
        />
      </div>
    )
  } else if (checkState === 'dislike') {
    likeState = false
    dislikeState = true
    return (
      <div>
        <Checkbox
          {...label}
          icon={<ThumbDownOffAltIcon htmlColor="#AAC8C7" />}
          checkedIcon={<ThumbDownAltIcon htmlColor="#f04e69" />}
          checked={dislikeState}
          title="dislike"
          disabled
        />
      </div>
    )
  } else {
    likeState = false
    dislikeState = false
    return (
      <div>
        <Checkbox
          {...label}
          icon={<ThumbUpOffAltIcon htmlColor="#AAC8C7" />}
          checkedIcon={<ThumbUpAltIcon htmlColor="#1976d2" />}
          onChange={onLikeChangeEvent}
          checked={likeState}
          title="like"
        />
        <Checkbox
          {...label}
          icon={<ThumbDownOffAltIcon htmlColor="#AAC8C7" />}
          checkedIcon={<ThumbDownAltIcon htmlColor="#1976d2" />}
          onChange={onDislikeChangeEvent}
          checked={dislikeState}
          title="dislike"
        />
      </div>
    )
  }
}
