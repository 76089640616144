// React imports
import React, { useEffect, useRef } from 'react'

// Material-UI imports
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import AttachFileIcon from '@mui/icons-material/AttachFile'

// Component imports
import ChatViz from 'component/Chat/chat-viz/ChatViz'
import RichTextField from 'component/Chat/RichTextField/RichTextField'
import SelectAttachments from 'component/Chat/SelectAttachments/SelectAttachments'

// Constants import
import {
  CHAT_DRAWER_WIDTH,
  IS_MOBILE,
  IS_ATTACHMENTS_ENABLED
} from 'utils/Constants'

// Stylesheet import
import './ChatPanel.css'

const ChatPanel = ({
  isChatDrawerOpen,
  chat,
  open,
  setOpen,
  chatUpdater,
  datasourceDetails
}) => {
  const history = chat.history
  const botProgress = chat.botProgress
  const responseStream = chat.responseStream
  const ignoredDataSources = chat.ignoredDataSources
  const attachments = chat.attachments
  const textFieldRef = useRef(null)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [textFieldValue, setTextFieldValue] = React.useState('')
  const [isSelectAttachmentOpen, setIsSelectAttachmentOpen] =
    React.useState(false)
  const _CHAT_DRAWER_WIDTH = IS_MOBILE ? 0 : CHAT_DRAWER_WIDTH
  function toggleSelectAttachment () {
    setIsSelectAttachmentOpen(!isSelectAttachmentOpen)
  }
  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }
  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }
  const drawerControls = { handleDrawerClose, handleDrawerOpen, drawerOpen }
  useEffect(() => {
    // In Computers Set focus on the TextField when the bot message received or activeSession changes
    if (!IS_MOBILE) textFieldRef.current.focus()
  }, [botProgress, chat.activeSession])

  const onSuggestionSelection = (example) => {
    setTextFieldValue(example)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleKeyPress = (event) => {
    chatUpdater.handleKeyPress(event, () => {
      onSendClick()
    })
  }

  const onSendClick = () => {
    chatUpdater.onSendClick(textFieldValue)
    setTextFieldValue('')
    setIsSelectAttachmentOpen(false)
  }

  // Find if any message is in editing state
  const messgeInEditState = history.some((message) => message.isEditing)
  // Disable UserQueryControl if bot response is loading or any of the message in history is in edit state
  const isUserQueryControlDisabled =
    botProgress || responseStream || messgeInEditState
  // Disable Send Button if text field is empty
  const isSendButtonDisabled =
    isUserQueryControlDisabled || textFieldValue.trim() === ''
  const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <div
      className="chat-panel-main"
      style={{ marginLeft: isChatDrawerOpen ? _CHAT_DRAWER_WIDTH : 0 }}
    >
      <ChatViz
          history={history}
          botProgress={botProgress}
          vizSize="medium"
          onLikeClick={chatUpdater.onLikeCliked}
          onDisLikeClick={chatUpdater.onDislikeCliked}
          responseStream={responseStream}
          updatePlot={chatUpdater.updatePlot}
          editMessage={chatUpdater.editMessage}
          sendDataToAnalyzer={chatUpdater.sendDataToAnalyzer}
          activeSession={chat.activeSession}
          attachments={attachments}
          navigateDatasheetPage={chatUpdater.navigateDatasheetPage}
        />
        
      {isSelectAttachmentOpen && (
        <SelectAttachments
          attachments={attachments}
          onAttachmentUpdate={chatUpdater.handleAttachmentUpdate}
          scope="session"
        />
      )}
      <div
        className="card-footer text-muted d-flex justify-content-start align-items-center p-3"
        id="footer"
        style={{
          width: !isChatDrawerOpen
            ? '100vw'
            : `calc(100% - ${_CHAT_DRAWER_WIDTH}px)`
        }}
      >
        <RichTextField
          name="Prompt-Outlined"
          placeholder={
            IS_MOBILE
              ? 'Type here...'
              : 'Type in your question...'
            }
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          onKeyDown={handleKeyPress}
          inputRef={textFieldRef}
          disabled={isUserQueryControlDisabled}
        />
        {IS_ATTACHMENTS_ENABLED && (
          <AttachFileIcon
            sx={{ cursor: 'pointer' }}
            onClick={toggleSelectAttachment}
          />
        )}

        <Button
          className="message-actions"
          variant="contained"
          endIcon={<SendIcon />}
          onClick={onSendClick}
          disabled={isSendButtonDisabled}
        >
          Send
        </Button>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" onClose={handleSnackbarClose}>
          Something went wrong while responding. Please clear the message and
          start fresh.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ChatPanel


