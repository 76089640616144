import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

export default function ExportChatDialog ({ open, onClose, handleExport }) {
  const exportOptions = [
    { display: 'PDF', value: 'PDF' },
    // { display: 'Python Notebook', value: 'ipynb' }
  ]
  const [selectedExportOption, setSelectedExportOption] = useState(
    exportOptions[0].value
  )
  function handleExportSelect (e) {
    setSelectedExportOption(e.target.value)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: '5px',
          padding: '0px 20px'
        }
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bolder',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>Export Chat Session</div>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center'
        }}
      >
        <TextField
          select
          size="small"
          id="demo-simple-select"
          value={selectedExportOption}
          onChange={handleExportSelect}
          sx={{
            flexGrow: '1',
            width: '190px',
            padding: '0px',
            marginBottom: '1.5em'
          }}
        >
          {exportOptions.map((option, index) => (
            <MenuItem value={option.value} key={index}>
              {option.display}
            </MenuItem>
          ))}
        </TextField>

        <br />
        <div style={{ display: 'flex', gap: '1em' }}>
          <Button
            variant="contained"
            onClick={() => handleExport(selectedExportOption)}
          >
            Export
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
