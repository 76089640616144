import { BASE_URL } from 'utils/Constants'

const likeEndpoint = BASE_URL + '/v1/likeresponse'
const dislikeEndpoint = BASE_URL + '/v1/dislikeresponse'
const dataSourceDetailsEndpoint = BASE_URL + '/v1/datasourcedetails'
const keywordsEndpoint = BASE_URL + '/v1/get-keywords'
const visualizationEndpoint = BASE_URL + '/v1/visualize-dataframe'
const dataSummaryEndpoint = BASE_URL + '/v1/dataframe-summary'
const editVisualizationEndpoint = BASE_URL + '/v1/edit-visualization'
const exportChatEndpoint = BASE_URL + '/v1/export-chat'
const getTodaysEventEndpoint = BASE_URL + '/v1/get-todays-event'

function getAboutPage (func) {
  const apiEndpoint = BASE_URL + '/v1/about'
  fetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      console.log('Received About Page from Server: ', data)
      func(data)
    })
    .catch((error) => {
      console.error('Error when recieving the About Page: ', error)
    })
}

function getUserInfo (func) {
  const apiEndpoint = BASE_URL + '/.auth/me'
  fetch(apiEndpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      console.log('Received user info: ', data)
      func(data)
    })
    .catch((error) => {
      console.error('Error while retreiving the user info: ', error)
    })
}

function getUserImage (func) {
  const userAccessToken = localStorage.getItem('userAccessToken')
  const apiEndpoint = 'https://graph.microsoft.com/v1.0/me/photos/240x240/$value'
  fetch(apiEndpoint, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userAccessToken}`,
      'Content-Type': 'image/jpg'
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    })
    .then((data) => {
      func(data)
    })
    .catch((error) => {
      console.error('Error while retreiving the user image: ', error)
    })
}

function getDatasourceDetails (func) {
  fetch(dataSourceDetailsEndpoint)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json() // this returns a Promise
    })
    .then((data) => {
      console.log('Received DataSource Details from Server: ', data)
      return func(data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

function getKeywords (func) {
  fetch(keywordsEndpoint)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json() // this returns a Promise
    })
    .then((data) => {
      console.log('Received Keywords from Server: ', data)
      return func(data)
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

function uploadFile (payload,successCallback,errorCallback) {
  const apiEndpoint = BASE_URL + '/v1/datasheet/file'
  const formData = new FormData()
  formData.append('file', payload)

  fetch(apiEndpoint, {
    method: 'POST',
    headers: { accept: 'multipart/form-data' },
    body: formData
  }).then((response) =>response.json())
  .then((data) => {
    if (data["error"]){
      console.error('Error when recieving File Upload Response: ', data)
      return errorCallback({
        name : data["error"]["name"],
        message: data["error"]["message"],
        status: 500,
        isConnectionAlive: true
      })
      
    }
    console.log('Received File Upload Response from Server: ', data)
    return successCallback(data)
  }).catch((error) => {
    const errorData = {
      message: "Something went wrong while getting File Upload Response. Please try again later.",
      status: 500,
      isConnectionAlive: true
    }
    errorCallback(errorData)
    console.error('Error when recieving File Upload Response: ', error)
  })
}

function uploadPDFURL (link,successCallback,errorCallback) {
  const apiEndpoint = BASE_URL +
  '/v1/datasheet/url?' +
  new URLSearchParams({ url: link })

  fetch(apiEndpoint, {
    method: 'POST'
  }).then((response) =>response.json()).then((data) => {
    if (data["error"]){
      console.error('Error when recieving File Upload Response: ', data)
      return errorCallback({
        name : data["error"]["name"],
        message: data["error"]["message"],
        status: 500,
        isConnectionAlive: true
      })
      
    } 
    console.log('Received File Upload Response from Server: ', data)
    return successCallback(data)
  }).catch((error) => {
    const errorData = {
      message: "Unable to process the URL, please check the URL or use file upload.",
      status: 500,
      isConnectionAlive: true
    }
    errorCallback(errorData)
    console.error('Error when recieving File Upload Response: ', error)
  })
}

function pollDataSheetStatus(datasheet_id,successCallback,inprogressCallback,errorCallback){
  const apiEndpoint = BASE_URL +
  '/v1/datasheet/'+datasheet_id

  fetch(apiEndpoint, {
    method: 'GET'
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    return response.json() // this returns a Promise
  }).then((data) => {
    if (data["error"]){
      console.error('Error when recieving DataSheet Status: ', data)
      return errorCallback({
        name : data["error"]["name"],
        message: data["error"]["message"],
        status: 500,
        isConnectionAlive: true
      })
      
    }
    if(data["status"]){
      if (data["status"]["value"] == "COMPLETED"){
        console.log('Received DataSheet Status from Server: ', data)
        return successCallback(data)
      }
      else if (data["status"]["value"] == "IN_PROGRESS" || data["status"]["value"] == "IN_QUEUE"){
        setTimeout(()=>pollDataSheetStatus(datasheet_id,successCallback,inprogressCallback,errorCallback),1000)
        return inprogressCallback(data)
      }
      else {
        errorCallback({
          message:"Something went wrong while preparing DataSheet"
        })
      }

    }
  }).catch((error) => {
    const errorData = {
      message: "Something went wrong while getting DataSheet Status. Please try again later.",
      status: 500,
      isConnectionAlive: true
    }
    errorCallback(errorData)
    console.error('Error when recieving DataSheet Status: ', error)
  })
}

function sendLike (chat, questionChat) {
  console.log('like', {
    Human: questionChat.value,
    AI: chat.value
  })
  fetch(likeEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Human: questionChat.value,
      AI: chat.value
    })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json() // this returns a Promise
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

function sendDislike (chat, questionChat) {
  console.log('dislike', {
    Human: questionChat.value,
    AI: chat.value
  })
  fetch(dislikeEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Human: questionChat.value,
      AI: chat.value
    })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json() // this returns a Promise
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}
function sendObjectiveHTTP (request, func, catchfunc) {
  // TODO : remove this to fetch from the server
  // return func({ ...stepsResponse, sessionID: request.sessionID });
  const payload = {input:request.question , additional_input : {...request} }
  console.log('Client sent the objective via Http: ', payload)
  
  fetch(BASE_URL + '/v1/task', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then((data) => {
      if (data["error"]){
        console.error('Error when recieving the Chat response: ', data)
        return catchfunc({
          name : data["error"]["name"],
          message: data["error"]["message"],
          status: 500,
          isConnectionAlive: true
        })
        
      }
      func(data)
      console.log('Response from server:', data)
    })
    .catch((error) => {
      catchfunc(error)
      console.error('Error:', error)
    })
}

function sendObjectiveAgentProtocolHTTP (request, func, catchfunc) {
  // TODO : remove this to fetch from the server
  // return func({ ...stepsResponse, sessionID: request.sessionID });
  const payload = {input:request.question , additional_input : {...request} }
  console.log('Client sent the objective via AgentProtocol : ', payload)
  fetch(BASE_URL + "/ap/v1/agent/tasks", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then((data) => {
            getAgentProtocolStep(data.task_id,func,catchfunc)
    })
    .catch((error) => {
      catchfunc(error)
      console.error('Error:', error)
    })
}
function getAgentProtocolStep(taskId, func, catchfunc) {
  fetch(BASE_URL + "/ap/v1/agent/tasks/"+taskId+"/steps", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({input:"",additional_input:{}})
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then((data) => {
            func(data)
      console.log('Response from server:', data)
      if(data.is_last === false)
      getAgentProtocolStep(taskId,func,catchfunc)
    })
    .catch((error) => {
      catchfunc(error)
      console.error('Error:', error)
    })
}

function getDataSummary (data, func) {
  // return `FileName: ${data.fileName}
  // Columns: ${Object.keys(data.data).join(" ")}`;
  fetch(dataSummaryEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ df: data.data, fileName: data.fileName })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      console.log('Received summary from Server: ', data)
      func(data)
    })
    .catch((error) => {
      console.error('Error when recieving the summary: ', error)
    })
}

// TODO: abstract the fetch call
function getVisualization (data, func, catchfunc, type) {
  fetch(
    type && type === 'edit'
      ? editVisualizationEndpoint
      : visualizationEndpoint,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )
    .then((response) =>response.json())
    .then((data) => {
      if (data["error"]){
        console.error('Error when recieving the Visualization: ', data)
        return catchfunc({
          name : data["error"]["name"],
          message: data["error"]["message"],
          status: 500,
          isConnectionAlive: true
        })
        
      } 
      
      console.log('Received Visualization from Server: ', data)
      func(data)
    })
    .catch((error) => {
      // TODO: differentiate between network error and server error to display session expiry
      const errorData = {
        message: "Something went wrong. Please try again later.",
        status: 500,
        isConnectionAlive: false
      }
      catchfunc(errorData)
      console.error('Error when recieving the Visualization: ', error)
    })
}

function getChatExportData (payload, func) {
  fetch(exportChatEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...payload, url: BASE_URL })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      console.log('Received Chat Export data from Server: ', data)
      func(data)
    })
    .catch((error) => {
      console.error('Error when recieving the Chat Export data: ', error)
    })
}

function getTodaysEvent (func) {
  const today = new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' })
  const [month, day] = today.split('/')
  const formattedTodayDate = `${day}/${month}`
  fetch(getTodaysEventEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ date: formattedTodayDate })
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    })
    .then((data) => {
      console.log('Received Today`s event from Server: ', data)
      func(data)
    })
    .catch((error) => {
      console.error('Error getting Today`s event: ', error)
    })
}

export {
  getAboutPage,
  getUserInfo,
  getUserImage,
  getDatasourceDetails,
  getKeywords,
  uploadPDFURL,
  uploadFile,
  pollDataSheetStatus,
  sendLike,
  sendDislike,
  sendObjectiveHTTP,
  getDataSummary,
  getVisualization,
  getChatExportData,
  getTodaysEvent,
  sendObjectiveAgentProtocolHTTP
}
