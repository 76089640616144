import React, { useState, useEffect } from 'react'

import SimpleAppBar from 'container/AppBar/AppBar'
import AboutPanel from 'container/AboutPanel/AboutPanel'
import ChatBaseContainer from 'container/ChatBaseContainer/ChatBaseContainer'
import ChatBotSocket from 'services/SocketCommunication'
import AnalyzerContainer from 'container/AnalyzerContainer/AnalyzerContainer'
// import { BOT_NAME } from 'utils/Constants'

import './App.css'

// document.title = BOT_NAME

function App () {
  const [tab, setTab] = useState('Vina')
  const [socketObject, setSocketObject] = useState(undefined)

  useEffect(() => {
    const chatBotSocketObject = new ChatBotSocket()
    setSocketObject(chatBotSocketObject)
  }, [])

  const onAppBarPanelChange = (event) => {
    console.log(event.target.title)
    setTab(event.target.title)
  }

  function getTabComponent (tab) {
    if (tab === 'Vina') {
      return (
        <div className="app-container-base">
          <ChatBaseContainer socketObject={socketObject} />
        </div>
      )
    } else if (tab === 'Analyzer') {
      return <AnalyzerContainer />
    } else {
      return (
        <div className="chat-container-base">
          <AboutPanel />
        </div>
      )
    }
  }

  return (
    <div className="app">
      <SimpleAppBar onClick={onAppBarPanelChange} mode={tab} />
      {getTabComponent(tab)}
    </div>
  )
}

export default App
