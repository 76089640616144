import React from 'react'
import { useSelector } from 'react-redux'

import './AboutPanel.css'

const AboutPanel = () => {
  const aboutContent = useSelector(
    (state) => state.ChatBaseContainer.aboutContent
  )

  return <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
}

export default AboutPanel
