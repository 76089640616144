import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

export default function SessionExpiredDialog ({ isConnectionAlive }) {
  return (
    <Dialog
      open={!isConnectionAlive}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: '20px'
        }
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bolder'
        }}
      >
        Session Expired
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center'
        }}
      >
        Please refresh the page to login again or to re-establish the
        connection.
        <br /> If this persists, feel free to contact Vina support team for
        assistance.
      </DialogContent>
    </Dialog>
  )
}
