// React imports
import React, { useState, useEffect, useRef } from 'react'

// Material-UI imports
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop'
import Paper from '@mui/material/Paper'


// Component imports
import BasicChatList from 'component/Sidebar/SideTabs/SideTabs'
import ChatPanel from 'container/ChatPanel/ChatPanel'
import SessionExpiredDialog from 'component/PopUp/SessionExpiredDialog'
import UploadPDF from 'component/Chat/uploadPDF/uploadPDF'
import BotProgress from 'component/Chat/bot_progress/BotProgress'
import PdfViewer from 'component/Chat/pdfViewer/pdfViewer'

// Redux imports
import {
  UPDATE_USER_HISTORY,
  UPDATE_BOT_HISTORY,
  UPDATE_PLOT,
  UPDATE_BOT_FEEDBACK_STATE,
  UPDATE_IGNORED_DATASOURCES,
  CREATE_SESSION,
  DELETE_SESSION,
  UPDATE_ACTIVE_SESSION,
  UPDATE_SESSION_NAME,
  UPDATE_DATASOURCE_DETAILS,
  TRIM_BOT_HISTORY,
  EDIT_MESSAGE,
  UPDATE_CHAT_DRAWER_STATE,
  UPDATE_CONNECTION_STATE,
  UPDATE_ABOUT_CONTENT,
  UPDATE_USER_NAME,
  UPDATE_ATTACHMENTS,
  UPDATE_DATASHEET
} from './ChatBaseContainerSlice'
import {
  UPLOAD_FILE,
  UPDATE_SUMMARY,
  UPDATE_SLIDE,
  UPDATE_NETWORK_STATUS
} from '../AnalyzerContainer/AnalyzerContainerSlice'
import { useSelector, useDispatch } from 'react-redux'

// Service and utility function imports
import {
  getDatasourceDetails,
  getAboutPage,
  getUserInfo,
  sendLike,
  sendDislike,
  pollDataSheetStatus,
  getVisualization ,
  sendObjectiveAgentProtocolHTTP,
  sendObjectiveHTTP,
  uploadFile,
  uploadPDFURL
} from 'services/HttpAPIRequests'
import {
  loadKeywords,
  generateUniqueFileName,
  addMetadataToPayload,
  SessionTimer,
  areValuesDeeplyEqual
} from 'utils/helpers'

// Constants import
import {
  CHAT_DRAWER_WIDTH,
  IS_MOBILE,
  CUSTOM_VINA_MESSAGE,
  COMMUNICATION_MODE,
  COMMUNICATION_MODES
} from 'utils/Constants'
import { useReactToPrint } from 'react-to-print'

// Stylesheet import
import './ChatBaseContainer.css'
import { Stack, TextField } from '@mui/material'


let connectionTimeoutID
// eslint-disable-next-line react/display-name
const PrintableChatPanel = React.forwardRef((props, ref) => {
  // Your component code here...
  return (
    <div ref={ref} className={`print-container ${props.customClass}`}>
      <ChatPanel {...props} />
    </div>
  )
})
export default function ChatBaseContainer ({ socketObject }) {
  const _CHAT_DRAWER_WIDTH = IS_MOBILE ? 0 : CHAT_DRAWER_WIDTH
  
  const componentRef = useRef()
  const [analyzerPopup, setAnalyzerPopup] = useState({ open: false })
  const [errorPopup, setErrorrPopup] = useState({ open: false })
  const [chatAreaTextFieldValue, setChatAreaTextFieldValue] = useState('')
  
  const fileData = useSelector((state) => state.AnalyzerContainer.fileData)

  const drawerIsOpen = useSelector(
    (state) => state.ChatBaseContainer.isChatDrawerOpen
  )
  const isConnectionAlive = useSelector(
    (state) => state.ChatBaseContainer.isConnectionAlive
  )
  const activeSession = useSelector(
    (state) => state.ChatBaseContainer.activeSession
  )

  const history = useSelector(
    (state) => state.ChatBaseContainer.sessions[activeSession].history
  )
  const attachments = useSelector(
    (state) => state.ChatBaseContainer.sessions[activeSession].attachments
  )
  const botProgress = useSelector(
    (state) => state.ChatBaseContainer.sessions[activeSession].botProgress
  )
  const responseStream = useSelector(
    (state) => state.ChatBaseContainer.sessions[activeSession].responseStream
  )
  const ignoredDataSources = useSelector(
    (state) =>
      state.ChatBaseContainer.sessions[activeSession].ignoredDataSources
  )
  const datasheet = useSelector((state) => state.ChatBaseContainer.sessions[activeSession].datasheet,areValuesDeeplyEqual)
  const datasourceDetails = useSelector(
    (state) => state.ChatBaseContainer.datasourceDetails
  )
  const [open, setOpen] = React.useState(false)

  const dispatch = useDispatch()
  // dispatchChat will update to activeSession
  const dispatchChat = React.useCallback(
    (action) => {
      const data = action.payload
      action.payload = { sessionID: activeSession, data }
      dispatch(action)
    },
    [dispatch, activeSession]
  )
  useEffect(() => {
    
    if (socketObject) {
      socketObject.onConnect(() => {
        localStorage.setItem('communication_mode', COMMUNICATION_MODES.socket)
        clearTimeout(connectionTimeoutID)
        dispatch(UPDATE_CONNECTION_STATE({ isConnectionAlive: true }))
        getDatasourceDetails((response) => {
          dispatch(UPDATE_DATASOURCE_DETAILS(response))
        })
        loadKeywords()
        getAboutPage((data) => {
          dispatch(UPDATE_ABOUT_CONTENT({ aboutContent: data.content }))
        })
        getUserInfo((data) => {
          const userInfo = data ? data[0] : undefined
          if (userInfo) {
            localStorage.setItem('userAccessToken', userInfo.access_token)
          }
          const userClaims = userInfo?.user_claims
          const nameObj = userClaims?.find(({ typ }) => typ === 'name')
          const userName = nameObj?.val

          dispatch(UPDATE_USER_NAME({ userName }))
        })
      })
      socketObject.onDisconnect((err) => {
        console.log('socket disconnected ', err)
        connectionTimeoutID = setTimeout(() => {
          dispatch(UPDATE_CONNECTION_STATE({ isConnectionAlive: false }))
        }, 5000) // consider as disconnected if reconnection does not happen for 5 seconds
      })
      socketObject.onError((sessionID,error_message) => {
        dispatch(
          UPDATE_BOT_HISTORY({
            sessionID,
            data: {
              answer: error_message,
              sessionID,
              steps: []
            }
          })
        )
      })
      socketObject.onGetAnswer((data) => {
        dispatch(UPDATE_BOT_HISTORY({ sessionID: data.sessionID, data }))
      }, onRequestTimeoutCallback)
    }
  }, [socketObject])

  const onRequestTimeoutCallback = React.useCallback(
    (sessionID) => {
      dispatch(
        UPDATE_BOT_HISTORY({
          sessionID,
          data: {
            answer: CUSTOM_VINA_MESSAGE.TIMEOUT,
            sessionID,
            steps: []
          }
        })
      )
    },
    [dispatch]
  )
  const onRequestErrorCallback = React.useCallback(
    (sessionID,error) => {
      let vina_error_message = CUSTOM_VINA_MESSAGE.PROCESSING_ERROR
      
      // TODO: Find a better way to classify custom backend error instead of error.isConnectionAlive 
      if (error.isConnectionAlive && error.message) {
        // Custom error message from backend
        vina_error_message = error.message
      }

      dispatch(
        UPDATE_BOT_HISTORY({
          sessionID,
          data: {
            answer: vina_error_message,
            sessionID,
            steps: []
          }
        })
      )
      setOpen(true)
    },
    [dispatch]
  )
  
  const sessions = useSelector((state) => state.ChatBaseContainer.sessions,
  (previousState,currentState)=>{
    // Only re-render if a Session is added or removed and the session name or createdAt  is changed
    const previousStateSessionIDs = Object.keys(previousState)
    const currentStateSessionIDs = Object.keys(currentState)
    
    if (previousStateSessionIDs.length !== currentStateSessionIDs.length) return false

    for (const sessionID in currentState){
      const previousStateSession = previousState[sessionID]
      const previousStateSessionName = previousStateSession.datasheet?.file_name || previousStateSession.sessionName
      const currentStateSession = previousState[sessionID]
      const currentStateSessionName = currentStateSession.datasheet?.file_name || currentStateSession.sessionName
      
      if (previousStateSessionName !== currentStateSessionName) return false
      if (previousStateSession.createdAt !== currentStateSession.createdAt) return false
    }
    
    return true
  })

  const chatListSessions = useSelector((state) => state.ChatBaseContainer.sessions)

  const ChatList = Object.keys(chatListSessions).map((key) => {
    return {
      id: key,
      name: chatListSessions[key].datasheet?.file_name || chatListSessions[key].sessionName,
      createdAt: chatListSessions[key].createdAt
    }
  })

  // sort the chatlist based on the time of creation to show recent chat at the top
  ChatList.sort((a, b) => b.createdAt - a.createdAt)

  const onSelectedDataSourcesChange = React.useCallback(
    (ignoredDataSources) => {
      dispatchChat(UPDATE_IGNORED_DATASOURCES(ignoredDataSources))
      console.log(ignoredDataSources)
    },
    [dispatchChat]
  )

  function createHistoryFormat (history) {
    return history.map((chat) => {
      if (chat.name === 'user') return chat.name + ': ' + chat.value
      else return chat.name + ': ' + chat.value.answer
    })
  }

  const onSendClick = React.useCallback(
    (message, chatHistory, selectedAttachments) => {
      if (chatHistory === undefined) {
        chatHistory = history
      }
      if (!selectedAttachments) {
        selectedAttachments = attachments.selected
      }
      // Code here for Send logic
      if (message.trim() !== '') {
        if (chatHistory.length === 0) {
          dispatch(UPDATE_SESSION_NAME(message))
        }
        dispatch(
          UPDATE_USER_HISTORY({
            sessionID: activeSession,
            question: message,
            selectedAttachments
          })
        )
        let selectedAttachmentsData = selectedAttachments.map(
          (attachmentName) => attachments.attachments[attachmentName]
        )
        // filter undefined or null data
        selectedAttachmentsData = selectedAttachmentsData.filter(
          (data) => data
        )
        const objective = {
          question: message,
          chatHistory: createHistoryFormat(chatHistory.slice(-10)),
          sessionID: activeSession,
          ignoredDataSources,
          attachments: selectedAttachmentsData,
          metaData:{}
        }
        const requestPayload = addMetadataToPayload(objective)

        requestPayload.metaData.datasheetinfo = datasheet
        
        SessionTimer.startTimer(activeSession,onRequestTimeoutCallback)

        if (COMMUNICATION_MODE === COMMUNICATION_MODES.HTTP_AGENT_PROTOCOL) {
          sendObjectiveAgentProtocolHTTP(requestPayload, (response) => {
            const data = response.additional_input
            dispatch(
              UPDATE_BOT_HISTORY({ sessionID: data.sessionID, data })
            )
          }, (error)=>onRequestErrorCallback(requestPayload.sessionID,error))
        } else if (COMMUNICATION_MODE === COMMUNICATION_MODES.HTTP_DIRECT) {
          sendObjectiveHTTP(requestPayload, (response) => {
            dispatch(
              UPDATE_BOT_HISTORY({ sessionID: response.sessionID, data:response })
            )
          }, (error)=>onRequestErrorCallback(requestPayload.sessionID,error))
        } else {
          const requestPayloadCopy = JSON.parse(JSON.stringify(requestPayload))
          requestPayloadCopy.experimentalSettings = JSON.parse(localStorage.getItem('chatData')).experimentalSettings
          socketObject.sendObjective(
            requestPayloadCopy,
            onRequestTimeoutCallback
          )
        }
      } else {
        console.log('Enter prompt before clicking send.')
      }
    },
    [
      dispatch,
      dispatchChat,
      socketObject,
      activeSession,
      history,
      ignoredDataSources,
      attachments,
      datasheet
    ]
  )

  const handleKeyPress = React.useCallback((event, sendCallback) => {
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      event.preventDefault() // Prevents the default form submission behavior
      sendCallback() // Indicate to send
    }
  }, [])

  const onLikeCliked = React.useCallback(
    (index) => {
      dispatchChat(
        UPDATE_BOT_FEEDBACK_STATE({ index, feedbackState: 'like' })
      )
      sendLike(history[index], history[index - 1])
    },
    [history, dispatchChat]
  )

  const onDislikeCliked = React.useCallback(
    (index) => {
      dispatchChat(
        UPDATE_BOT_FEEDBACK_STATE({ index, feedbackState: 'dislike' })
      )
      sendDislike(history[index], history[index - 1])
    },
    [history, dispatchChat]
  )

  // Side bar for chat list functionalities
  const onNewChatButtonClick = () => {
    dispatch(CREATE_SESSION())
  }

  const onChatTabClick = (ChatSessionID) => {
    // console.log(ChatSessionID);
    dispatch(UPDATE_ACTIVE_SESSION(ChatSessionID))
  }

  const onChatDeleteClick = (ChatSessionID) => {
    dispatch(DELETE_SESSION(ChatSessionID))
  }
  const updatePlot = (plotData, messageId) => {
    dispatch(
      UPDATE_PLOT({
        sessionID: activeSession,
        plotData,
        messageId
      })
    )
  }
  const editMessage = ({ id, type, message, action, selectedAttachments }) => {
    if (action === 'submit') {
      if (type === 'user') {
        dispatch(TRIM_BOT_HISTORY({ sessionID: activeSession, messageID: id }))
        const updatedHistory = []
        for (const message of history) {
          if (message.id === id) break
          updatedHistory.push(message)
        }
        onSendClick(message, updatedHistory, selectedAttachments)
      } else {
        const botmessage = history.find((message) => message.id === id)
        console.log(botmessage)
        dispatch(TRIM_BOT_HISTORY({ sessionID: activeSession, messageID: id }))
        const editedBotMessage = {
          ...botmessage.value,
          answer: message,
          edited: true
        }
        dispatchChat(UPDATE_BOT_HISTORY(editedBotMessage))
      }
    } else {
      dispatch(
        EDIT_MESSAGE({
          sessionID: activeSession,
          messageID: id,
          editAction: action
        })
      )
    }
  }
  function sendDataToAnalyzer (data) {
    if (analyzerPopup.open) return
    data.fileName = generateUniqueFileName(
      data.fileName,
      Object.keys(fileData)
    )

    dispatch(UPLOAD_FILE({ data }))

    setAnalyzerPopup({
      open: true,
      message: 'Data sent to analyzer successfully',
      type: 'success'
    })

    getVisualization(
      { data: data.data, goal: {} },
      (response) => {
        dispatch(
          UPDATE_SUMMARY({ fileName: data.fileName, summary: response.summary })
        )
        dispatch(
          UPDATE_SLIDE({
            fileName: data.fileName,
            imageBlob: 'data:image/png;base64,' + response.raster,
            text: { Visualization: response.text },
            slideAction: 'CREATE',
            code: response.code
          })
        )
      },
      (error) => {
        dispatch(UPDATE_NETWORK_STATUS({ error }))
      }
    )
  }
  function handleSnackbarClose () {
    console.log({ ...analyzerPopup, open: false })
    setAnalyzerPopup({ ...analyzerPopup, open: false })
  }
  function handleErrorPopupClose(){
    setErrorrPopup({...errorPopup,open:false})
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  function handleAttachmentUpdate (attachments) {
    const sessionID = activeSession
    dispatch(UPDATE_ATTACHMENTS({ sessionID, attachments }))
  }

  function datasheetUploadErrorCallback(error){
    dispatchChat(UPDATE_DATASHEET({...datasheet,isUploading:false}))
    setErrorrPopup({...errorPopup,open:true,message:error.message})
  }
  const handlePDFUpload = (status, file) => {
    console.log(status, file)
    
    dispatchChat(UPDATE_DATASHEET({...datasheet,isUploading:true}))
    uploadFile(
      file,
      (fileUploadResponse)=>{
        pollDataSheetStatus(
          fileUploadResponse["datasheet_id"],
          (datasheetPollResponse)=>{ // Success Callback
            dispatchChat(UPDATE_DATASHEET({
              ...datasheet,
              blob_file_url: fileUploadResponse["blob_file_url"],
              collection_name: datasheetPollResponse["collection_name"],
              datasheet_id:fileUploadResponse["datasheet_id"],
              file_name:fileUploadResponse["file_name"],
              num_pages:fileUploadResponse["num_pages"],
              isUploading:false,
              status: datasheetPollResponse["status"]
            }))
          },
          (datasheetPollResponse) => { // In Progress Callback
            dispatchChat(UPDATE_DATASHEET({
              ...datasheet,
              blob_file_url: fileUploadResponse["blob_file_url"],
              collection_name: datasheetPollResponse["collection_name"],
              datasheet_id:fileUploadResponse["datasheet_id"],
              file_name:fileUploadResponse["file_name"],
              num_pages:fileUploadResponse["num_pages"],
              isUploading:true,
              status: datasheetPollResponse["status"]
            }))
          },
          datasheetUploadErrorCallback // Error Callback
        )
      },
      datasheetUploadErrorCallback // Error Callback
    )
  }
  const handleURLSubmit = (event) => {

    const pdfUrl = event.clipboardData.getData('text/plain')
    
    // Clear the text field
    setChatAreaTextFieldValue('')

    dispatchChat(UPDATE_DATASHEET({...datasheet,isUploading:true}))
    uploadPDFURL(
      pdfUrl,
      (fileUploadResponse)=>{ // Success Callback
        pollDataSheetStatus(
          fileUploadResponse["datasheet_id"],
          (datasheetPollResponse)=>{
            dispatchChat(UPDATE_DATASHEET({
              ...datasheet,
              blob_file_url: fileUploadResponse["blob_file_url"],
              collection_name: datasheetPollResponse["collection_name"],
              datasheet_id:fileUploadResponse["datasheet_id"],
              file_name:fileUploadResponse["file_name"],
              num_pages:fileUploadResponse["num_pages"],
              isUploading:false,
              status: datasheetPollResponse["status"]
            }))
          },
          (datasheetPollResponse) => { // In Progress Callback
            dispatchChat(UPDATE_DATASHEET({
              ...datasheet,
              blob_file_url: fileUploadResponse["blob_file_url"],
              collection_name: datasheetPollResponse["collection_name"],
              datasheet_id:fileUploadResponse["datasheet_id"],
              file_name:fileUploadResponse["file_name"],
              num_pages:fileUploadResponse["num_pages"],
              isUploading:true,
              status: datasheetPollResponse["status"]
            }))
          },
          datasheetUploadErrorCallback // Error Callback
        )
      },
      datasheetUploadErrorCallback // Error Callback
    )
  }
  function toggleViewer(){
    dispatchChat(UPDATE_DATASHEET({...datasheet,isOpen:!datasheet.isOpen}))
  }
  function navigateDatasheetPage(node){
    const pageNumber = node.page
    const highlightText = node.text

    const _datasheet = {...datasheet}
    if (pageNumber>0 && pageNumber<=datasheet.totalPages)
      _datasheet.currentPageNumber = pageNumber
      _datasheet.highlightText = node.highlighted_text
      // _datasheet.updatedNodes = node.sources
    
    // if (highlightText)
    //   _datasheet.highlightText = highlightText

    dispatchChat(UPDATE_DATASHEET(_datasheet)) 
    
  }

  function num_page_to_time(num_pages){
    const minutes = Math.floor(num_pages * 0.5 / 60);
    const seconds = Math.floor((num_pages * 0.5) % 60);
    return `${minutes > 0 ? `${minutes} minutes and ` : ''}${Math.max(seconds, 10)} seconds`;
  }

  function onDocumentLoadSuccess({ numPages }) {
    if (numPages!==datasheet.totalPages)
    dispatchChat(UPDATE_DATASHEET({...datasheet,totalPages:numPages}))
  }

  function handleDatasheetPageChange(event,selectedPageNumber) {
    const _datasheet = {...datasheet}

  if (selectedPageNumber>0 && selectedPageNumber<=datasheet.totalPages){
    _datasheet.currentPageNumber = selectedPageNumber
    _datasheet.isOpen = true
    _datasheet.highlightText = ''
  }
  if (datasheet.nodes?.length>0){
    for (const node of datasheet.nodes){
      if ( node.page == selectedPageNumber){
        _datasheet.retreivedPageNumber = selectedPageNumber
        _datasheet.highlightText = node.highlighted_text
        break
  
    }
  
  }
  }

  dispatchChat(UPDATE_DATASHEET(_datasheet))
  }

  return (
    <div id="chat-base-container-base">
      <div
        className="drawer-control"
        onClick={() => dispatch(UPDATE_CHAT_DRAWER_STATE(!drawerIsOpen))}
      >
        {drawerIsOpen
          ? (
          <KeyboardArrowLeftSharpIcon />
            )
          : (
          <KeyboardArrowRightSharpIcon />
            )}
      </div>
     {!IS_MOBILE && datasheet?.status?.value === 'COMPLETED' && <div className='pdfViewer-control' onClick={toggleViewer}>
    { !datasheet.isOpen
          ? (
          <KeyboardArrowLeftSharpIcon />
            )
          : (
          <KeyboardArrowRightSharpIcon />
            )   
    }
    </div>}
    

      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            position: 'absolute',
            top: IS_MOBILE ? 56 : 64,
            width: CHAT_DRAWER_WIDTH,
            height: 'calc(100vh - 64px)',
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={drawerIsOpen}
      >
        <BasicChatList
          chatList={ChatList}
          activeSession={activeSession}
          onChatTabClick={onChatTabClick}
          onChatDeleteClick={onChatDeleteClick}
          handlePrint={handlePrint}
        />

        <div id="side-bar-new-chat-button-base">
          <Button
            variant="contained"
            id="side-bar-new-chat-button"
            onClick={onNewChatButtonClick}
          >
            New Chat
          </Button>
        </div>
      </Drawer>
        {
        datasheet?.status?.value === 'COMPLETED'
          ? (
            <div style={{display:"flex"}}>
          <PrintableChatPanel
            customClass={datasheet?.isOpen ? 'half-width' : ''}
            ref={componentRef}
            isChatDrawerOpen={drawerIsOpen}
            socketObject={socketObject}
            datasourceDetails={datasourceDetails}
            open={open}
            setOpen={setOpen}
            chat={{
              // TODO: pass the whole session instead of individual entry like botProgress , responseStream
              history,
              botProgress,
              responseStream,
              ignoredDataSources,
              activeSession,
              attachments
            }}
            chatUpdater={{
              onLikeCliked,
              onDislikeCliked,
              handleKeyPress,
              onSendClick,
              onSelectedDataSourcesChange,
              updatePlot,
              editMessage,
              sendDataToAnalyzer,
              handleAttachmentUpdate,
              navigateDatasheetPage
            }}
          /> {!IS_MOBILE && datasheet.isOpen && 
            <PdfViewer 
            datasheet={datasheet}
            handleDatasheetPageChange={handleDatasheetPageChange}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            />}
            </div>
            )
          : (
          <div
            style={{
              marginLeft: drawerIsOpen ? _CHAT_DRAWER_WIDTH : 0,
              height: '100%',
            }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              position:"relative",
              height: '50%',
              width:  '50%',
              top: '50%',
              left: '50%',
              transform: "translate(-50%, -50%)",
              gap: '1em'
            }}>
            <UploadPDF handleFileUpload={handlePDFUpload}/>
            <p style={{margin:"0px" ,color:"grey"}}>OR</p>
             <TextField
                id="pdf-url"
                placeholder='Paste Datasheet PDF URL to Upload'
                value={chatAreaTextFieldValue}
                onPaste={handleURLSubmit}
              />
              </div>
          </div>
            )
      }
      <SessionExpiredDialog isConnectionAlive={isConnectionAlive} />
      <Snackbar
        open={analyzerPopup.open}
        autoHideDuration={analyzerPopup.type === 'info' ? undefined : 6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {analyzerPopup.open && (
          <MuiAlert severity={analyzerPopup.type} elevation={2}>
            {analyzerPopup.message}
          </MuiAlert>
        )}
      </Snackbar>
      
      <Snackbar
        open={errorPopup.open}
        autoHideDuration={6000}
        onClose={handleErrorPopupClose}
      >
        <MuiAlert severity="error" elevation={2}>
          {errorPopup.message}
        </MuiAlert>
      </Snackbar>

      <Backdrop className="backdrop" open={datasheet?.isUploading} sx={{ zIndex: 9, left: drawerIsOpen ? _CHAT_DRAWER_WIDTH : 0 }}>
          <Paper sx={{padding:"10px" }}>
            <BotProgress
              sx={{ position: 'absolute'}}
              text=" is processing your data"
              overrideWithChild={true}
              flexColumn="column"
            >
              {datasheet?.status?.value != "IN_PROGRESS" && <div>Datasheet preparation in Queue</div>}
              {datasheet?.status?.value === "IN_PROGRESS" && <div>{datasheet.status.message}</div>}
              {datasheet?.status?.value === "IN_PROGRESS" && datasheet?.num_pages && <div>Approximate time taken for this datasheet is around {num_page_to_time(datasheet.num_pages)}</div>}
            </BotProgress>
          </Paper>
      </Backdrop>
    </div>
  )
}
