const DOMAIN_NAME = window.location.hostname

// let BASE_URL = (DOMAIN_NAME.includes("127.0.0") || DOMAIN_NAME.includes("localhost")) ? "http://127.0.0.1:5000": "https://solichat.azurewebsites.net"
let BASE_URL = ''
let BOT_NAME = ''
const storeDataVersion = '1.0'
const IS_ATTACHMENTS_ENABLED = false
// TODO: Remove BASE_URL except localhost
if (DOMAIN_NAME.includes('solichat.')) {
  BASE_URL = 'https://solichat.azurewebsites.net'
  BOT_NAME = 'Vina'
} else if (DOMAIN_NAME.includes('vina.')) {
  BASE_URL = 'https://vina.solitontech.com'
  BOT_NAME = 'Vina'
} else if (DOMAIN_NAME.includes('solichatbeta')) {
  BASE_URL = 'https://solichatbeta.azurewebsites.net'
  BOT_NAME = 'Vina (beta)'
} else {
  BASE_URL = localStorage.getItem('base_url') || 'http://localhost:8003'
  BOT_NAME = 'Vina'
}

// Reset the base URL in production to make same origin requests, (React will set NODE_ENV to "production" while building)
if (process.env.NODE_ENV === 'production') {
  BASE_URL = localStorage.getItem('base_url') || window.location.origin
  BOT_NAME = 'Vina'
}

const IS_MOBILE = window.innerWidth < 400
const CHAT_DRAWER_WIDTH = 200
const CUSTOM_VINA_MESSAGE = {
  TIMEOUT:
    "Oops, it seems there is a timeout. Please try again. If the issue persists, contact Vina support at Soliton for assistance.",
  PROCESSING_ERROR:
    'Oops, it seems there is an issue processing your request. Please try again.'
}
const ERROR_MESSAGE = {
  DEFAULT: 'Something went wrong while responding. Please retry',
  INVALID_DATA_FOR_EDIT: (slideFileName, selectedFileName) =>
    `Cannot edit plot which is generated with ${slideFileName} with ${selectedFileName} , please select ${slideFileName} and retry `,
  INVALID_COMMUNICATION_MODE: (communicationMode) =>`Invalid communication mode: ${communicationMode}`,
  FILE_PROCESSING_ERROR: 'Unable to process the file',
  }
function getCommunicationModeInfo(communicationMode) {
  return `Selected Communication mode : ${communicationMode}

Available Communication modes:
${Object.values(COMMUNICATION_MODES).map((mode)=> `    - ${mode}`).join('\n')}
  
Use localStorage.setItem('communication_mode', '<mode>') to set the communication mode and reload the page`
  
}
const APPBAR_VINA_DESCRIPTION = 'Your AI Companion' // Use "AI Copilot for Test" for demos
const COMMUNICATION_MODES = {
  SOCKET: 'socket',
  HTTP_DIRECT: 'http_direct',
  HTTP_AGENT_PROTOCOL: 'http_agent_protocol'
}

let communicationMode = localStorage.getItem('communication_mode')
console.log(getCommunicationModeInfo(communicationMode))
if (!Object.values(COMMUNICATION_MODES).includes(communicationMode)) {
  console.log(ERROR_MESSAGE.INVALID_COMMUNICATION_MODE(communicationMode))
  communicationMode = COMMUNICATION_MODES.SOCKET
}
const COMMUNICATION_MODE = communicationMode

export {
  BASE_URL,
  BOT_NAME,
  storeDataVersion,
  CHAT_DRAWER_WIDTH,
  IS_MOBILE,
  CUSTOM_VINA_MESSAGE,
  ERROR_MESSAGE,
  IS_ATTACHMENTS_ENABLED,
  APPBAR_VINA_DESCRIPTION,
  COMMUNICATION_MODE,
  COMMUNICATION_MODES
}
